var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-wrap-outer", class: { mobile: _vm.isMobile } },
    [
      _c("QuickStartModal"),
      _vm._v(" "),
      _c("AuthModal", { attrs: { "form-to-load": "login" } }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "page-wrap",
          class: { mobile: _vm.isMobile, "nav-open": _vm.navOpen }
        },
        [
          _vm.isMobile
            ? _c("NavMobile", { attrs: { "nav-open": _vm.navOpen } })
            : _vm._e(),
          _vm._v(" "),
          _vm.isDesktop
            ? _c("Header", { attrs: { "layout-type": "default" } })
            : _vm._e(),
          _vm._v(" "),
          _vm.showError
            ? _c("Error", { attrs: { error: _vm.error, stack: _vm.stack } })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "default", class: _vm.pageTheme },
            [
              _vm.isMobile || !_vm.hasCustomLayout
                ? _c("PageHeader", { attrs: { name: _vm.pageName } })
                : _vm._e(),
              _vm._v(" "),
              _vm.currentTheme === "inbox"
                ? _c("InboxActions", { attrs: { folder: _vm.folder } })
                : _vm._e(),
              _vm._v(" "),
              _c(
                "b-container",
                {
                  staticClass: "content-container",
                  attrs: { fluid: !_vm.isDesktop || _vm.pageName === "Inbox" }
                },
                [
                  _vm.pageName === "Questionnaire"
                    ? _c(
                        "b-row",
                        { staticClass: "breadcrumbs-title" },
                        [
                          _c(
                            "b-col",
                            { staticClass: "breadcrumbs" },
                            [
                              _c("Breadcrumb", {
                                attrs: {
                                  items: _vm.pageBreadcrumbs,
                                  title: "Questionnaire Details"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "b-row",
                    { staticClass: "page-content-wrapper" },
                    [
                      _c(
                        "b-col",
                        { staticClass: "page-content" },
                        [_c("nuxt")],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      !_vm.navOpen ? _c("Footer") : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }