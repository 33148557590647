//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters } from 'vuex'
import { userRoles } from '@/server/constants'
import { uppercaseFirst } from '@/server/utils/utils-string'

export default {
  name: 'NavItems',
  props: {
    displayType: {
      type: String,
      default: 'text'
    },
    items: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...mapGetters({
      isDesktop: 'breakpoints/desktop',
      navOpen: 'global/navOpen',
      unreadMessages: 'messages/getUnreadMessageCount',
      userActingAs: 'user/getUserActingAs',
      userRole: 'user/getUserRole',
      user: 'user/user'
    }),
    navItems() {
      return this.items.filter(i => !!(i.security & this.userActingAs) && !i.ignore)
    },
    urlProjectParam() {
      return this.$route.params?.projectid
    },
    isProjectOwner() {
      return this.userRole & userRoles.projectowner
    }
  },
  methods: {
    ...mapActions({
      toggleMenu: 'global/toggleMenu'
    }),
    closeMobileMenu() {
      if (this.navOpen) {
        this.toggleMenu([])
      }
    },
    getIcon(item) {
      if (item === 'details') {
        return 'information-outline'
      } else if (item === 'questions') {
        return 'head-question'
      } else if (item === 'requirements') {
        return 'text-box-check'
      } else if (item === 'participants' || item === 'delegates') {
        return 'account-group'
      } else if (item === 'properties') {
        return 'office-building'
      } else {
        return item
      }
    },
    findPath(item) {
      if (['details', 'requirements', 'questions', 'participants', 'properties', 'delegates'].includes(item)) {
        return `/projects/${this.urlProjectParam}/${item}`
      } else if (item === 'mapviewer') {
        const { _id } = this.user
        return `loismapviewer/buildings?organization=${this.$config.lassoMapViewerId}:${_id}`
      } else {
        return `/${item}/`
      }
    },
    formattedItem(item) {
      return uppercaseFirst(item)
    }
  }
}
