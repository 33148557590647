//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'QuestionDetailItem',
  props: {
    question: {
      type: Object,
      default: () => {}
    },
    // normal = full owner capabilities or readonly = questions can be viewed by participants but not answered
    mode: {
      type: String,
      default: 'normal'
    }
  },
  data() {
    return {
      referrer: null
    }
  },
  computed: {
    ...mapGetters({
      isDesktop: 'breakpoints/desktop',
      questions: 'questions/getQuestions',
      projectPhaseCount: 'projects/getActiveProjectPhaseCount',
      templatePhaseCount: 'templates/getActiveTemplatePhaseCount',
      isActingAsReviewer: 'user/isActingAsReviewer'
    }),
    questionPhaseExceedsPhaseCount() {
      return this.referrer === 'template'
        ? this.question.phase > this.templatePhaseCount
        : this.question.phase > this.projectPhaseCount
    },
    questionPhaseExceedsTooltip() {
      return `Phase does not exist for this ${this.referrer}. Update the question to a valid phase.`
    }
  },
  mounted() {
    const route = { ...this.$route.params }
    this.referrer = route.templateid ? 'template' : 'project'
  },
  methods: {
    ...mapActions({
      deleteQuestion: 'questions/deleteQuestion',
      setLastQuestionDeleted: 'questions/setLastQuestionDeleted',
      updateQuestion: 'questions/updateQuestionById'
    }),
    answerTypeLabel(type) {
      let label = null
      if (type === 0) {
        label = 'Text'
      } else if (type === 1) {
        label = 'Numeric'
      } else {
        label = 'Choice'
      }
      return label
    },
    async deleteQuestionConfirm(question) {
      const h = this.$createElement
      const customMsg = h(
        'div',
        [
          'Are you sure you want to delete ',
          h('strong', `"${question.text}"? `),
          'This action removes question answers (if any) for this project and cannot be undone!'
        ]
      )
      const res = await this.$bvModal.msgBoxConfirm([customMsg], {
        title: 'Confirm Question Delete!',
        okVariant: 'danger',
        okTitle: 'YES',
        cancelVariant: 'light',
        cancelTitle: 'NO',
        footerBgVariant: 'dark',
        headerBgVariant: 'warning',
        hideHeaderClose: true,
        centered: true
      })
      if (res) {
        await this.deleteQuestionFromProject(question._id)
      }
    },
    async deleteQuestionFromProject(questionid) {
      const res = await this.deleteQuestion(questionid)
      if (res && !this.questions.length) {
        this.setLastQuestionDeleted(true)
        this.successNotification()
      }
    },
    editQuestion(questionid) {
      const { projectid, templateid } = this.$route.params
      if (templateid) {
        this.$router.push(`/templates/${templateid}/question/${questionid}/edit-question`)
      } else if (projectid) {
        this.$router.push(`/projects/${projectid}/questions/${questionid}/edit-question`)
      }
    },
    successNotification() {
      this.$bvToast.toast('Question was deleted.', {
        autoHideDelay: 2000,
        noCloseButton: true,
        solid: true,
        toaster: 'b-toaster-top-right',
        title: 'Success',
        variant: 'success'
      })
    }
  }
}
