//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters } from 'vuex'
import { isEqual } from 'lodash-es'

export default {
  name: 'CategoriesQuestion',
  props: {
    noninteractive: {
      type: Boolean,
      default: false
    },
    questionDisplayType: {
      type: String,
      default: 'detail' // answer type indicates to QuestionList to display the answers vs details
    },
    // normal = full owner capabilities or readonly = questions can be viewed by participants but not answered
    mode: {
      type: String,
      default: 'normal'
    }
  },
  data() {
    return {
      categoriesOpen: true,
      editCategory: false,
      loadingCategoriesQuestions: false,
      loadingQuestions: false,
      route: {
        projectid: null,
        questionid: null,
        templateid: null
      },
      categoryOwner: null,
      categoryType: null,
      showBackToTopButton: false,
      sortCategories: false,
      selectAll: false
      // selectAll passes true when user clicks the Select Category and false when they click Deselect Category
      // watchers check when the questionsSelected and question list changes, so it can reevaluate what action
      // is displayed to the user - this also determines whether to add or delete from the selectedQuestions list
      // if a category has all questions selected then "Deselect" mode is set
      // if category has at least 1 question unselected, the "Select Category" mode is set
    }
  },
  computed: {
    ...mapGetters({
      activeCategory: 'questions/getActiveCategory',
      activeProjectPhase: 'projects/getActiveProjectPhase',
      isMobile: 'breakpoints/mobile',
      isProjectOwner: 'user/isProjectOwner',
      isOrgAdmin: 'user/isOrgAdmin',
      isListAdmin: 'user/isListAdmin',
      isSuperAdmin: 'user/isSuperAdmin',
      isActingAsReviewer: 'user/isActingAsReviewer',
      loading: 'global/loading',
      questions: 'questions/getQuestions',
      questionsSelected: 'questions/getQuestionsSelected',
      questionsSelectActive: 'questions/getQuestionsSelectActive',
      primaryCategories: 'questions/getPrimaryCategories',
      primaryCategory: 'questions/getPrimaryCategory',
      projectIsActive: 'projects/getProjectIsActive',
      secondaryCategory: 'questions/getSecondaryCategory',
      project: 'projects/getActiveProject',
      template: 'templates/getActiveTemplate'
    }),
    activeWithinPropertyDetail() {
      const { propertyid } = this.$route.params
      return propertyid !== undefined
    },
    questionsNoRequirements() {
      return this.questions.filter(q => !q.requirement)
    },
    showCategoryButtons() {
      return (this.primaryCategories.length && !this.uncategorizedOnly) || (this.uncategorizedOnly && this.questions.length)
    },
    showEditCategoryButton() {
      return !this.sortCategories && this.activeCategory && this.activeCategory !== 'Uncategorized' && this.questionDisplayType === 'detail' &&
        (this.isProjectOwner || this.isOrgAdmin || this.isListAdmin || this.isSuperAdmin)
    },
    showDeleteAllCategoriesButton() {
      return !this.sortCategories && (this.isOrgAdmin || this.isListAdmin || this.isSuperAdmin)
    },
    uncategorizedOnly() {
      return this.primaryCategories.length === 1 && this.primaryCategories[0] === 'Uncategorized'
    }
  },
  watch: {
    questionsSelected() {
      // this condition will trigger when "select all" is chosen, when questions selected individually or by category
      this.setSelectAllMode()
    },
    questions() {
      // this condition will trigger when the category is changed and question list is refreshed
      this.setSelectAllMode()
    },
    async activeProjectPhase(newVal) {
      if (newVal) {
        await this.refreshCategories()
      }
    },
    isMobile(newVal) {
      if (!newVal) {
        this.categoriesOpen = true
      }
    },
    primaryCategories(oldVal, newVal) {
      if (!isEqual(oldVal, newVal)) {
        this.editCategory = false
      }
    }
  },
  async mounted() {
    this.loadingCategoriesQuestions = true
    this.route = { ...this.$route.params }
    this.categoryOwner = this.route.templateid ? this.route.templateid : this.route.projectid
    this.categoryType = this.route.templateid ? 1 : 2
    this.categoryToEdit = this.activeCategory
    // if viewed within a template or there is an active project set, refresh on mount,
    // otherwise watcher will update when active project is set
    if (this.categoryType === 1 || this.activeProjectPhase) {
      await this.refreshCategories()
    }
    this.loadingCategoriesQuestions = false
    window.addEventListener('scroll', this.showBackToTop)
  },
  beforeDestroy() {
    this.selectAll = false
  },
  destroyed() {
    window.removeEventListener('scroll', this.showBackToTop)
  },
  methods: {
    ...mapActions({
      deleteQuestions: 'questions/deleteQuestions',
      fetchQuestions: 'questions/fetchQuestions',
      fetchPrimaryCategories: 'questions/fetchPrimaryCategories',
      fetchSecondaryCategories: 'questions/fetchAllSecondaryCategories',
      setActiveCategory: 'questions/setActiveCategory',
      setPrimaryCategory: 'questions/setPrimaryCategory',
      setSecondaryCategory: 'questions/setSecondaryCategory',
      setQuestionsSelected: 'questions/setQuestionsSelected',
      setQuestionsSelectedAdd: 'questions/setQuestionsSelectedAdd',
      setQuestionsSelectedDelete: 'questions/setQuestionsSelectedDelete',
      startLoading: 'global/startLoading',
      stopLoading: 'global/stopLoading'
    }),
    async deleteAllQuestions() {
      this.loadingQuestions = true
      await this.deleteQuestions({ owner: this.categoryOwner, type: this.categoryType })
      this.loadingQuestions = false
    },
    async deleteAllQuestionsConfirm() {
      const { projectid, templateid } = this.$route.params
      const h = this.$createElement
      const customMsg = h(
        'div',
        [
          'Are you sure you want to delete ALL categories and questions for ',
          h('strong', `"${templateid ? this.template.name : this.project.name}"? `),
          `${projectid ? 'This action removes all answered questions for this project and cannot be undone!' : ''}`
        ]
      )
      const res = await this.$bvModal.msgBoxConfirm([customMsg], {
        title: 'Confirm Categories and Questions Delete!',
        okVariant: 'danger',
        okTitle: 'YES',
        cancelVariant: 'light',
        cancelTitle: 'NO',
        footerBgVariant: 'dark',
        headerBgVariant: 'warning',
        hideHeaderClose: true,
        centered: true
      })
      if (res) {
        await this.deleteAllQuestions()
      }
    },
    getOffsetTop(el) {
      let offsetTop = 0
      while (el) {
        offsetTop += el.offsetTop
        el = el.offsetParent
      }
      return offsetTop
    },
    goToTop() {
      const offset = this.isMobile ? '75' : '100'
      const el = document.getElementById('categories-list')
      window.scrollTo(el.offsetLeft, this.getOffsetTop(el) - offset)
    },
    handleSelectQuestions() {
      if (this.selectAll) {
        this.questions.forEach((question) => {
          if (!question.requirement) {
            this.setQuestionsSelectedAdd({ _id: question._id, text: question.text })
          }
        })
      } else {
        this.questions.forEach((question) => {
          if (!question.requirement) {
            this.setQuestionsSelectedDelete({ _id: question._id, text: question.text })
          }
        })
      }
    },
    setSelectAllMode() {
      const categoryHasAllSelected = this.questionsNoRequirements.every(question => this.questionsSelected.find(qs => qs._id === question._id))
      if (categoryHasAllSelected) {
        this.selectAll = true
      } else {
        this.selectAll = false
      }
    },
    showBackToTop(e) {
      const offset = this.isMobile ? '10' : '75'
      const el = document.getElementById('categories-list')
      const pos = el ? el.getBoundingClientRect().top : 0
      if (pos < offset) {
        this.showBackToTopButton = true
      } else {
        this.showBackToTopButton = false
      }
    },
    async refreshCategories() {
      await this.fetchPrimaryCategories({ owner: this.categoryOwner, type: this.categoryType, viewPhase: this.activeWithinPropertyDetail })
      this.setActiveCategory('Uncategorized')
      const sessionObj = JSON.parse(localStorage.getItem('categoryselections'))
      if (sessionObj) {
        let activeCategory
        if (sessionObj[this.categoryOwner]?.primaryCategory) {
          this.setPrimaryCategory(sessionObj[this.categoryOwner].primaryCategory)
          await this.fetchSecondaryCategories({ owner: this.categoryOwner, type: this.categoryType, viewPhase: this.activeWithinPropertyDetail })
          activeCategory = sessionObj[this.categoryOwner].primaryCategory
        }
        if (sessionObj[this.categoryOwner]?.secondaryCategory) {
          this.setSecondaryCategory(sessionObj[this.categoryOwner].secondaryCategory)
          activeCategory = sessionObj[this.categoryOwner].secondaryCategory
        }
        this.setActiveCategory(activeCategory || 'Uncategorized')
        await this.fetchQuestions({ owner: this.categoryOwner, type: this.categoryType, viewPhase: this.activeWithinPropertyDetail })
      } else {
        localStorage.removeItem('categoryselections')
      }
      await this.fetchQuestions({ owner: this.categoryOwner, type: this.categoryType, viewPhase: this.activeWithinPropertyDetail })
      if (!this.questions.length && this.activeCategory === 'Uncategorized') {
        this.setActiveCategory('')
      }
    }
  }
}
