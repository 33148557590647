var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    { staticClass: "form-login" },
    [
      _c(
        "b-row",
        { attrs: { "align-h": "center" } },
        [
          _c(
            "b-col",
            [
              _c(
                "b-form",
                {
                  staticClass: "form-wrapper rounded-lg mb-3",
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.onLogin()
                    }
                  }
                },
                [
                  _c("b-alert", {
                    attrs: {
                      show: !!_vm.errorMsg,
                      variant: "danger",
                      fade: "",
                      dismissible: ""
                    },
                    domProps: { innerHTML: _vm._s(_vm.errorMsg) },
                    on: { dismissed: _vm.resetForm }
                  }),
                  _vm._v(" "),
                  _vm.showResend
                    ? _c("div", { staticClass: "resend-panel" }, [
                        _vm._v("\n          Click "),
                        _c(
                          "a",
                          {
                            attrs: { href: "#" },
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                $event.preventDefault()
                                return _vm.resendConfirmation($event)
                              }
                            }
                          },
                          [_vm._v("here")]
                        ),
                        _vm._v(" to resend confirmation email.\n          "),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: !_vm.canResend,
                                expression: "!canResend"
                              }
                            ]
                          },
                          [
                            _vm._v(
                              "\n            Message can be resent in " +
                                _vm._s(_vm.sendCountdown) +
                                " seconds.\n          "
                            )
                          ]
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.showNoMoreResends
                    ? _c("div", { staticClass: "resend-panel" }, [
                        _vm._v(
                          "\n          Max confirmation email sends have been reached,"
                        ),
                        _c("br"),
                        _vm._v("\n          please contact "),
                        _c(
                          "a",
                          {
                            attrs: {
                              target: "_new",
                              href: "mailto:support@lassoedapp.com"
                            }
                          },
                          [_vm._v("support@lassoedapp.com")]
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        id: "input-group-email",
                        "label-for": "input-email"
                      }
                    },
                    [
                      _c("b-form-input", {
                        attrs: {
                          id: "input-email",
                          state: _vm.validateState("email"),
                          type: "email",
                          required: "",
                          placeholder: "Email Address"
                        },
                        model: {
                          value: _vm.$v.loginForm.email.$model,
                          callback: function($$v) {
                            _vm.$set(_vm.$v.loginForm.email, "$model", $$v)
                          },
                          expression: "$v.loginForm.email.$model"
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "b-form-invalid-feedback",
                        { attrs: { id: "input-email-live-feedback" } },
                        [
                          _vm._v(
                            "\n            Email is required and should be formatted like john.doe@google.com.\n          "
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        id: "input-group-password",
                        "label-for": "input-password"
                      }
                    },
                    [
                      _c("b-form-input", {
                        attrs: {
                          id: "input-password",
                          state: _vm.validateState("password"),
                          type: "password",
                          required: "",
                          placeholder: "Password"
                        },
                        model: {
                          value: _vm.$v.loginForm.password.$model,
                          callback: function($$v) {
                            _vm.$set(_vm.$v.loginForm.password, "$model", $$v)
                          },
                          expression: "$v.loginForm.password.$model"
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "b-form-invalid-feedback",
                        { attrs: { id: "input-password-live-feedback" } },
                        [
                          _vm._v(
                            "\n            Password is required.\n          "
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _c(
                    "b-button",
                    {
                      staticClass: "action-primary",
                      attrs: {
                        type: "submit",
                        disabled: _vm.disableSubmit,
                        variant: "marketing",
                        block: ""
                      }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.submitButtonText) +
                          "\n        "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "action-primary flex" },
                    [
                      _c("label", [_vm._v("Log In with:")]),
                      _vm._v(" "),
                      _c(
                        "b-select",
                        {
                          staticClass: "login-with-select",
                          attrs: { placeholder: "Select SSO Provider" },
                          model: {
                            value: _vm.ssoLogin,
                            callback: function($$v) {
                              _vm.ssoLogin = $$v
                            },
                            expression: "ssoLogin"
                          }
                        },
                        _vm._l(_vm.ssoProviders, function(provider, idx) {
                          return _c(
                            "option",
                            { key: idx, domProps: { value: provider.value } },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(provider.label) +
                                  "\n            "
                              )
                            ]
                          )
                        }),
                        0
                      ),
                      _vm._v(" "),
                      _c(
                        "b-button",
                        {
                          staticClass:
                            "action-primary login-form login-as-button",
                          attrs: { type: "button", variant: "marketing" },
                          on: {
                            click: function($event) {
                              return _vm.$emit("ssologin", _vm.ssoLogin)
                            }
                          }
                        },
                        [_vm._v("\n            ...\n          ")]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-row",
                    { staticClass: "links", attrs: { "no-gutters": "" } },
                    [
                      _c(
                        "b-col",
                        { attrs: { md: "6" } },
                        [
                          _c(
                            "b-link",
                            {
                              attrs: { href: "" },
                              on: {
                                click: function($event) {
                                  return _vm.$emit("change-form", "register")
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n              New User Registration\n            "
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-col",
                        { attrs: { md: "6" } },
                        [
                          _c(
                            "b-link",
                            {
                              attrs: { href: "" },
                              on: {
                                click: function($event) {
                                  return _vm.$emit("change-form", "reset")
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n              Reset Password\n            "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-row",
                    { staticClass: "terms", attrs: { "no-gutters": "" } },
                    [
                      _c(
                        "b-col",
                        [
                          _vm._v(
                            "\n            By clicking Log In, you agree to our\n            "
                          ),
                          _c("b-link", { attrs: { to: "/terms" } }, [
                            _vm._v("\n              Terms of Use\n            ")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }