var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "question-list-details" },
    [
      _c(
        "draggable",
        {
          staticClass: "questions",
          attrs: { "ghost-class": "ghost", disabled: _vm.mode === "readonly" },
          model: {
            value: _vm.questionList,
            callback: function($$v) {
              _vm.questionList = $$v
            },
            expression: "questionList"
          }
        },
        _vm._l(_vm.questionList, function(question) {
          return _c(
            "b-row",
            {
              key: question._id,
              class:
                "question-item detail " +
                (_vm.calculatedMode === "readonly" && "readonly"),
              attrs: { "no-gutters": "" }
            },
            [
              _c("QuestionDetailItem", {
                attrs: { question: question, mode: _vm.calculatedMode }
              })
            ],
            1
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }