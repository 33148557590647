//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'
import { userProjectRoles, userRoles } from '@/server/constants'

const userRolesAll = userRoles.participant + userRoles.projectowner + userRoles.admin

const userRolesBoth = userRoles.participant + userRoles.projectowner

export default {
  name: 'Footer',
  data () {
    return {
      footerNavItems: [
        { page: 'inbox', security: userRolesAll },
        { page: 'projects', security: userRolesAll },
        { page: 'templates', security: userRoles.projectowner },
        { page: 'reports', security: userRoles.projectowner },
        { page: 'admin', security: userRoles.admin }
      ],
      footerNavItemsProjects: [
        { page: 'details', security: userRolesAll },
        { page: 'questions', security: userRolesBoth },
        { page: 'requirements', security: userRoles.projectowner },
        { page: 'participants', security: userRoles.projectowner },
        { page: 'properties', security: userRolesBoth },
        { page: 'delegates', security: userProjectRoles.delegator }
      ]
    }
  },
  computed: {
    ...mapGetters({
      isDesktop: 'breakpoints/desktop',
      loggedInUser: 'user/loggedInUser',
      pageTheme: 'global/pageTheme'
    }),
    isMarketingTheme() {
      return this.pageTheme === 'marketing'
    },
    navStyle() {
      return this.pageTheme === 'projects' && this.$route.params.projectid ? 'icon' : 'text'
    },
    selectedNav () {
      return this.pageTheme === 'projects' && this.$route.params.projectid ? this.footerNavItemsProjects : this.footerNavItems
    }
  }
}
