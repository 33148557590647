import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _3d70061e = () => interopDefault(import('../pages/admin/index.vue' /* webpackChunkName: "pages/admin/index" */))
const _6baf5314 = () => interopDefault(import('../pages/dashboard/index.vue' /* webpackChunkName: "pages/dashboard/index" */))
const _4244c368 = () => interopDefault(import('../pages/inbox/index.vue' /* webpackChunkName: "pages/inbox/index" */))
const _3bc1b42a = () => interopDefault(import('../pages/privacy/index.vue' /* webpackChunkName: "pages/privacy/index" */))
const _5082a50b = () => interopDefault(import('../pages/profile/index.vue' /* webpackChunkName: "pages/profile/index" */))
const _8671a724 = () => interopDefault(import('../pages/projects/index.vue' /* webpackChunkName: "pages/projects/index" */))
const _c235ae38 = () => interopDefault(import('../pages/questionnaires/index.vue' /* webpackChunkName: "pages/questionnaires/index" */))
const _21313952 = () => interopDefault(import('../pages/register/index.vue' /* webpackChunkName: "pages/register/index" */))
const _e6806cbe = () => interopDefault(import('../pages/reports/index.vue' /* webpackChunkName: "pages/reports/index" */))
const _9f40d21e = () => interopDefault(import('../pages/reset/index.vue' /* webpackChunkName: "pages/reset/index" */))
const _db44b89e = () => interopDefault(import('../pages/support/index.vue' /* webpackChunkName: "pages/support/index" */))
const _113564db = () => interopDefault(import('../pages/templates/index.vue' /* webpackChunkName: "pages/templates/index" */))
const _1e3bdd29 = () => interopDefault(import('../pages/terms/index.vue' /* webpackChunkName: "pages/terms/index" */))
const _fdb27a00 = () => interopDefault(import('../pages/admin/add.vue' /* webpackChunkName: "pages/admin/add" */))
const _04b5adbd = () => interopDefault(import('../pages/projects/add.vue' /* webpackChunkName: "pages/projects/add" */))
const _e37dc52c = () => interopDefault(import('../pages/templates/add.vue' /* webpackChunkName: "pages/templates/add" */))
const _59f0515c = () => interopDefault(import('../pages/admin/datafeeds/add.vue' /* webpackChunkName: "pages/admin/datafeeds/add" */))
const _c77cdd5e = () => interopDefault(import('../pages/admin/organizations/add.vue' /* webpackChunkName: "pages/admin/organizations/add" */))
const _62dff882 = () => interopDefault(import('../pages/reports/pdf/delegator/_projectid/index.vue' /* webpackChunkName: "pages/reports/pdf/delegator/_projectid/index" */))
const _957dfadc = () => interopDefault(import('../pages/reports/pdf/participant/_projectid/index.vue' /* webpackChunkName: "pages/reports/pdf/participant/_projectid/index" */))
const _d06a1a00 = () => interopDefault(import('../pages/admin/datafeeds/_datafeedid/index.vue' /* webpackChunkName: "pages/admin/datafeeds/_datafeedid/index" */))
const _5e130320 = () => interopDefault(import('../pages/admin/organizations/_organizationid/index.vue' /* webpackChunkName: "pages/admin/organizations/_organizationid/index" */))
const _11cb0b94 = () => interopDefault(import('../pages/reports/pdf/_projectid/index.vue' /* webpackChunkName: "pages/reports/pdf/_projectid/index" */))
const _02c6d04c = () => interopDefault(import('../pages/admin/datafeeds/_datafeedid/edit.vue' /* webpackChunkName: "pages/admin/datafeeds/_datafeedid/edit" */))
const _092c3c2c = () => interopDefault(import('../pages/admin/organizations/_organizationid/edit.vue' /* webpackChunkName: "pages/admin/organizations/_organizationid/edit" */))
const _1fb0e7a7 = () => interopDefault(import('../pages/admin/_userid/index.vue' /* webpackChunkName: "pages/admin/_userid/index" */))
const _457e94e0 = () => interopDefault(import('../pages/questionnaires/_questionnaireid/index.vue' /* webpackChunkName: "pages/questionnaires/_questionnaireid/index" */))
const _4bf8a080 = () => interopDefault(import('../pages/templates/_templateid/index.vue' /* webpackChunkName: "pages/templates/_templateid/index" */))
const _d8e7aa76 = () => interopDefault(import('../pages/admin/_userid/edit.vue' /* webpackChunkName: "pages/admin/_userid/edit" */))
const _7d76c10b = () => interopDefault(import('../pages/projects/_projectid/delegates/index.vue' /* webpackChunkName: "pages/projects/_projectid/delegates/index" */))
const _55e855bc = () => interopDefault(import('../pages/projects/_projectid/details.vue' /* webpackChunkName: "pages/projects/_projectid/details" */))
const _280ef600 = () => interopDefault(import('../pages/projects/_projectid/edit.vue' /* webpackChunkName: "pages/projects/_projectid/edit" */))
const _13459399 = () => interopDefault(import('../pages/projects/_projectid/participants/index.vue' /* webpackChunkName: "pages/projects/_projectid/participants/index" */))
const _5ad5a628 = () => interopDefault(import('../pages/projects/_projectid/properties/index.vue' /* webpackChunkName: "pages/projects/_projectid/properties/index" */))
const _d55967ec = () => interopDefault(import('../pages/projects/_projectid/questions/index.vue' /* webpackChunkName: "pages/projects/_projectid/questions/index" */))
const _336246ee = () => interopDefault(import('../pages/projects/_projectid/requirements/index.vue' /* webpackChunkName: "pages/projects/_projectid/requirements/index" */))
const _709029d8 = () => interopDefault(import('../pages/templates/_templateid/add-question.vue' /* webpackChunkName: "pages/templates/_templateid/add-question" */))
const _27b908e8 = () => interopDefault(import('../pages/templates/_templateid/edit.vue' /* webpackChunkName: "pages/templates/_templateid/edit" */))
const _3ae874cc = () => interopDefault(import('../pages/projects/_projectid/delegates/add.vue' /* webpackChunkName: "pages/projects/_projectid/delegates/add" */))
const _3769e3a8 = () => interopDefault(import('../pages/projects/_projectid/participants/add.vue' /* webpackChunkName: "pages/projects/_projectid/participants/add" */))
const _7334948a = () => interopDefault(import('../pages/projects/_projectid/properties/add.vue' /* webpackChunkName: "pages/projects/_projectid/properties/add" */))
const _33ec6f7a = () => interopDefault(import('../pages/projects/_projectid/properties/addpo.vue' /* webpackChunkName: "pages/projects/_projectid/properties/addpo" */))
const _01bd0459 = () => interopDefault(import('../pages/projects/_projectid/questions/add.vue' /* webpackChunkName: "pages/projects/_projectid/questions/add" */))
const _c451c70a = () => interopDefault(import('../pages/projects/_projectid/delegates/_delegateid/index.vue' /* webpackChunkName: "pages/projects/_projectid/delegates/_delegateid/index" */))
const _0a8f0e4a = () => interopDefault(import('../pages/projects/_projectid/participants/_participantid/index.vue' /* webpackChunkName: "pages/projects/_projectid/participants/_participantid/index" */))
const _f88071a8 = () => interopDefault(import('../pages/projects/_projectid/properties/_propertyid/index.vue' /* webpackChunkName: "pages/projects/_projectid/properties/_propertyid/index" */))
const _afbbe91e = () => interopDefault(import('../pages/projects/_projectid/delegates/_delegateid/edit.vue' /* webpackChunkName: "pages/projects/_projectid/delegates/_delegateid/edit" */))
const _1518b9de = () => interopDefault(import('../pages/projects/_projectid/participants/_participantid/edit.vue' /* webpackChunkName: "pages/projects/_projectid/participants/_participantid/edit" */))
const _32f8965c = () => interopDefault(import('../pages/projects/_projectid/questions/_questionid/edit-question.vue' /* webpackChunkName: "pages/projects/_projectid/questions/_questionid/edit-question" */))
const _0db5859a = () => interopDefault(import('../pages/templates/_templateid/question/_questionid/edit-question.vue' /* webpackChunkName: "pages/templates/_templateid/question/_questionid/edit-question" */))
const _61c90cf1 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/admin",
    component: _3d70061e,
    name: "admin"
  }, {
    path: "/dashboard",
    component: _6baf5314,
    name: "dashboard"
  }, {
    path: "/inbox",
    component: _4244c368,
    name: "inbox"
  }, {
    path: "/privacy",
    component: _3bc1b42a,
    name: "privacy"
  }, {
    path: "/profile",
    component: _5082a50b,
    name: "profile"
  }, {
    path: "/projects",
    component: _8671a724,
    name: "projects"
  }, {
    path: "/questionnaires",
    component: _c235ae38,
    name: "questionnaires"
  }, {
    path: "/register",
    component: _21313952,
    name: "register"
  }, {
    path: "/reports",
    component: _e6806cbe,
    name: "reports"
  }, {
    path: "/reset",
    component: _9f40d21e,
    name: "reset"
  }, {
    path: "/support",
    component: _db44b89e,
    name: "support"
  }, {
    path: "/templates",
    component: _113564db,
    name: "templates"
  }, {
    path: "/terms",
    component: _1e3bdd29,
    name: "terms"
  }, {
    path: "/admin/add",
    component: _fdb27a00,
    name: "admin-add"
  }, {
    path: "/projects/add",
    component: _04b5adbd,
    name: "projects-add"
  }, {
    path: "/templates/add",
    component: _e37dc52c,
    name: "templates-add"
  }, {
    path: "/admin/datafeeds/add",
    component: _59f0515c,
    name: "admin-datafeeds-add"
  }, {
    path: "/admin/organizations/add",
    component: _c77cdd5e,
    name: "admin-organizations-add"
  }, {
    path: "/reports/pdf/delegator/:projectid",
    component: _62dff882,
    name: "reports-pdf-delegator-projectid"
  }, {
    path: "/reports/pdf/participant/:projectid",
    component: _957dfadc,
    name: "reports-pdf-participant-projectid"
  }, {
    path: "/admin/datafeeds/:datafeedid",
    component: _d06a1a00,
    name: "admin-datafeeds-datafeedid"
  }, {
    path: "/admin/organizations/:organizationid",
    component: _5e130320,
    name: "admin-organizations-organizationid"
  }, {
    path: "/reports/pdf/:projectid",
    component: _11cb0b94,
    name: "reports-pdf-projectid"
  }, {
    path: "/admin/datafeeds/:datafeedid?/edit",
    component: _02c6d04c,
    name: "admin-datafeeds-datafeedid-edit"
  }, {
    path: "/admin/organizations/:organizationid?/edit",
    component: _092c3c2c,
    name: "admin-organizations-organizationid-edit"
  }, {
    path: "/admin/:userid",
    component: _1fb0e7a7,
    name: "admin-userid"
  }, {
    path: "/questionnaires/:questionnaireid",
    component: _457e94e0,
    name: "questionnaires-questionnaireid"
  }, {
    path: "/templates/:templateid",
    component: _4bf8a080,
    name: "templates-templateid"
  }, {
    path: "/admin/:userid/edit",
    component: _d8e7aa76,
    name: "admin-userid-edit"
  }, {
    path: "/projects/:projectid/delegates",
    component: _7d76c10b,
    name: "projects-projectid-delegates"
  }, {
    path: "/projects/:projectid/details",
    component: _55e855bc,
    name: "projects-projectid-details"
  }, {
    path: "/projects/:projectid/edit",
    component: _280ef600,
    name: "projects-projectid-edit"
  }, {
    path: "/projects/:projectid/participants",
    component: _13459399,
    name: "projects-projectid-participants"
  }, {
    path: "/projects/:projectid/properties",
    component: _5ad5a628,
    name: "projects-projectid-properties"
  }, {
    path: "/projects/:projectid/questions",
    component: _d55967ec,
    name: "projects-projectid-questions"
  }, {
    path: "/projects/:projectid/requirements",
    component: _336246ee,
    name: "projects-projectid-requirements"
  }, {
    path: "/templates/:templateid/add-question",
    component: _709029d8,
    name: "templates-templateid-add-question"
  }, {
    path: "/templates/:templateid/edit",
    component: _27b908e8,
    name: "templates-templateid-edit"
  }, {
    path: "/projects/:projectid/delegates/add",
    component: _3ae874cc,
    name: "projects-projectid-delegates-add"
  }, {
    path: "/projects/:projectid/participants/add",
    component: _3769e3a8,
    name: "projects-projectid-participants-add"
  }, {
    path: "/projects/:projectid/properties/add",
    component: _7334948a,
    name: "projects-projectid-properties-add"
  }, {
    path: "/projects/:projectid/properties/addpo",
    component: _33ec6f7a,
    name: "projects-projectid-properties-addpo"
  }, {
    path: "/projects/:projectid/questions/add",
    component: _01bd0459,
    name: "projects-projectid-questions-add"
  }, {
    path: "/projects/:projectid/delegates/:delegateid",
    component: _c451c70a,
    name: "projects-projectid-delegates-delegateid"
  }, {
    path: "/projects/:projectid/participants/:participantid",
    component: _0a8f0e4a,
    name: "projects-projectid-participants-participantid"
  }, {
    path: "/projects/:projectid/properties/:propertyid",
    component: _f88071a8,
    name: "projects-projectid-properties-propertyid"
  }, {
    path: "/projects/:projectid/delegates/:delegateid/edit",
    component: _afbbe91e,
    name: "projects-projectid-delegates-delegateid-edit"
  }, {
    path: "/projects/:projectid/participants/:participantid/edit",
    component: _1518b9de,
    name: "projects-projectid-participants-participantid-edit"
  }, {
    path: "/projects/:projectid/questions/:questionid/edit-question",
    component: _32f8965c,
    name: "projects-projectid-questions-questionid-edit-question"
  }, {
    path: "/templates/:templateid/question/:questionid?/edit-question",
    component: _0db5859a,
    name: "templates-templateid-question-questionid-edit-question"
  }, {
    path: "/",
    component: _61c90cf1,
    name: "index"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
