var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    { attrs: { fluid: "" } },
    [
      _c("LicensesUnavailableModal", { attrs: { "license-type": "rfi" } }),
      _vm._v(" "),
      _c(
        "b-row",
        { staticClass: "page-header-wrapper" },
        [
          _c(
            "b-container",
            [
              _c(
                "b-row",
                [
                  _vm.isDesktop
                    ? _c(
                        "b-col",
                        { staticClass: "header-search", attrs: { md: "5" } },
                        [
                          _c(
                            "b-row",
                            [
                              _c("b-col", [
                                _c("h1", { staticClass: "page-heading" }, [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.pageName) +
                                      "\n              "
                                  )
                                ])
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "b-col",
                    { staticClass: "header-actions" },
                    [
                      _c(
                        "b-row",
                        { staticClass: "actions-wrapper" },
                        [
                          _c(
                            "b-col",
                            { staticClass: "actions" },
                            [
                              _vm.showButton("new-participant") &&
                              _vm.isProjectOwner &&
                              !_vm.isActingAsReviewer
                                ? _c(
                                    "b-button",
                                    {
                                      staticClass: "new",
                                      attrs: {
                                        squared: "",
                                        variant: "link",
                                        size: "sm",
                                        title: "New Participant"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.handleAction(
                                            "new-participant"
                                          )
                                        }
                                      }
                                    },
                                    [_c("IconAddPrimary")],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.showButton("new-project") &&
                              _vm.isProjectOwner &&
                              !_vm.isActingAsReviewer
                                ? _c(
                                    "b-button",
                                    {
                                      staticClass: "new",
                                      attrs: {
                                        squared: "",
                                        variant: "link",
                                        size: "sm",
                                        title: "New Project"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.handleAction("new-project")
                                        }
                                      }
                                    },
                                    [_c("IconAddPrimary")],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.showButton("new-question") &&
                              _vm.isProjectOwner &&
                              !_vm.isActingAsReviewer
                                ? _c(
                                    "b-button",
                                    {
                                      staticClass: "new",
                                      attrs: {
                                        squared: "",
                                        variant: "link",
                                        size: "sm",
                                        title: "New Question"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.handleAction(
                                            "new-question"
                                          )
                                        }
                                      }
                                    },
                                    [_c("IconAddPrimary")],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.showButton("search-properties") &&
                              !_vm.isProjectOwner &&
                              _vm.projectActive &&
                              !_vm.participantSubmittedProject &&
                              _vm.isStatusParticipating &&
                              !_vm.participantIsEliminated &&
                              !_vm.isUserLoisTempNoCommunities
                                ? _c(
                                    "b-button",
                                    {
                                      staticClass: "btn-search-lois-properties",
                                      attrs: {
                                        variant: "link",
                                        title: "Search LOIS Properties"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.handleAction(
                                            "search-properties"
                                          )
                                        }
                                      }
                                    },
                                    [_c("IconSelectPropertyLarge")],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.showButton("refresh")
                                ? _c(
                                    "b-button",
                                    {
                                      class: { refreshing: _vm.isRefreshing },
                                      attrs: {
                                        squared: "",
                                        variant: "link",
                                        size: "sm",
                                        title: "Refresh"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.handleAction("refresh")
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "refresh-icon" },
                                        [
                                          _vm.refreshing === "refresh"
                                            ? _c("b-spinner", {
                                                attrs: { medium: "" }
                                              })
                                            : _c("IconRefresh")
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.showButton("list")
                                ? _c(
                                    "b-button",
                                    {
                                      attrs: {
                                        squared: "",
                                        variant: "link",
                                        size: "sm",
                                        title: "Projects List"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.handleAction("list")
                                        }
                                      }
                                    },
                                    [_c("IconList")],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.showButton("list-participants")
                                ? _c(
                                    "b-button",
                                    {
                                      attrs: {
                                        squared: "",
                                        variant: "link",
                                        size: "sm",
                                        title: "Participants List"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.handleAction(
                                            "list-participants"
                                          )
                                        }
                                      }
                                    },
                                    [_c("IconList")],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.showButton("list-questions")
                                ? _c(
                                    "b-button",
                                    {
                                      attrs: {
                                        squared: "",
                                        variant: "link",
                                        size: "sm",
                                        title: "Questions List"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.handleAction(
                                            "list-questions"
                                          )
                                        }
                                      }
                                    },
                                    [_c("IconList")],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.showButton("list-properties")
                                ? _c(
                                    "b-button",
                                    {
                                      attrs: {
                                        squared: "",
                                        variant: "link",
                                        size: "sm",
                                        title: "Properties List"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.handleAction(
                                            "list-properties"
                                          )
                                        }
                                      }
                                    },
                                    [_c("IconList")],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.showButton("edit-project") &&
                              _vm.isProjectOwner &&
                              !_vm.isActingAsReviewer
                                ? _c(
                                    "b-button",
                                    {
                                      attrs: {
                                        squared: "",
                                        variant: "link",
                                        size: "sm",
                                        title: "Edit Project"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.handleAction(
                                            "edit-project"
                                          )
                                        }
                                      }
                                    },
                                    [_c("IconEditPrimary")],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.showButton("details")
                                ? _c(
                                    "b-button",
                                    {
                                      attrs: {
                                        squared: "",
                                        variant: "link",
                                        size: "sm",
                                        title: "Project Details"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.handleAction("details")
                                        }
                                      }
                                    },
                                    [_c("IconDetails")],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.showButton("details-participant")
                                ? _c(
                                    "b-button",
                                    {
                                      attrs: {
                                        squared: "",
                                        variant: "link",
                                        size: "sm",
                                        title: "Participant Details"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.handleAction(
                                            "details-participant"
                                          )
                                        }
                                      }
                                    },
                                    [_c("IconDetails")],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.showButton("report-pdf")
                                ? _c(
                                    "b-button",
                                    {
                                      staticClass: "new",
                                      attrs: {
                                        squared: "",
                                        variant: "link",
                                        size: "sm",
                                        title: "Report PDF"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.handleAction("report-pdf")
                                        }
                                      }
                                    },
                                    [_c("IconReportPdf")],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.showButton("ownerParticipantToggle") &&
                              _vm.displayProjectListTypeDropdown
                                ? _c(
                                    "b-dropdown",
                                    {
                                      staticClass: "owner",
                                      attrs: { "aria-role": "list" },
                                      model: {
                                        value: _vm.projectListType,
                                        callback: function($$v) {
                                          _vm.projectListType = $$v
                                        },
                                        expression: "projectListType"
                                      }
                                    },
                                    [
                                      _c(
                                        "button",
                                        {
                                          staticClass: "button trigger-button",
                                          attrs: {
                                            slot: "trigger",
                                            type: "button"
                                          },
                                          slot: "trigger"
                                        },
                                        [
                                          _vm.projectListType === "owner"
                                            ? [
                                                _vm.isDesktop
                                                  ? _c("span", [
                                                      _vm._v("Projects You Own")
                                                    ])
                                                  : _c("span", [
                                                      _vm._v("Owner")
                                                    ])
                                              ]
                                            : _vm.projectListType ===
                                              "participant"
                                            ? [
                                                _vm.isDesktop
                                                  ? _c("span", [
                                                      _vm._v(
                                                        "Projects Participating"
                                                      )
                                                    ])
                                                  : _c("span", [
                                                      _vm._v("Participating")
                                                    ])
                                              ]
                                            : _vm.projectListType ===
                                              "inventory"
                                            ? [
                                                _vm.isDesktop
                                                  ? _c("span", [
                                                      _vm._v(
                                                        "Inventory Projects"
                                                      )
                                                    ])
                                                  : _c("span", [
                                                      _vm._v("Inventory")
                                                    ])
                                              ]
                                            : _vm.projectListType === "reviewer"
                                            ? [
                                                _vm.isDesktop
                                                  ? _c("span", [
                                                      _vm._v(
                                                        "Reviewer Projects"
                                                      )
                                                    ])
                                                  : _c("span", [
                                                      _vm._v("Review")
                                                    ])
                                              ]
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _c("b-icon", {
                                            attrs: { icon: "chevron-down" }
                                          })
                                        ],
                                        2
                                      ),
                                      _vm._v(" "),
                                      (_vm.isProjectOwner ||
                                        _vm.isOwnerAndParticipant) &&
                                      !_vm.isParticipantReviewer
                                        ? _c(
                                            "b-dropdown-item",
                                            {
                                              attrs: {
                                                value: "owner",
                                                "aria-role": "listitem"
                                              }
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "media" },
                                                [
                                                  _c("b-icon", {
                                                    staticClass: "media-left",
                                                    attrs: { icon: "account" }
                                                  }),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "media-content"
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "dbi-heading"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                        Projects Owned\n                      "
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "dbi-description"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                        Projects owned by you\n                      "
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.isOwnerAndParticipant ||
                                      (_vm.isOwnerOrParticipant &&
                                        _vm.isReviewer)
                                        ? _c(
                                            "b-dropdown-item",
                                            {
                                              attrs: {
                                                value: "participant",
                                                "aria-role": "listitem"
                                              }
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "media" },
                                                [
                                                  _c("b-icon", {
                                                    staticClass: "media-left",
                                                    attrs: {
                                                      icon: "account-multiple"
                                                    }
                                                  }),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "media-content"
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "dbi-heading"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                        Projects Participating\n                      "
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "dbi-description"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                        Projects you are participanting in\n                      "
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.displayInventoryProjectOption
                                        ? _c(
                                            "b-dropdown-item",
                                            {
                                              attrs: {
                                                value: "inventory",
                                                "aria-role": "listitem"
                                              }
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "media" },
                                                [
                                                  _c("b-icon", {
                                                    staticClass: "media-left",
                                                    attrs: { icon: "view-list" }
                                                  }),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "media-content"
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "dbi-heading"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                        Inventory Projects\n                      "
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "dbi-description"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                        Projects that store Property Inventories\n                      "
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.displayProjectReviewerOption
                                        ? _c(
                                            "b-dropdown-item",
                                            {
                                              attrs: {
                                                value: "reviewer",
                                                "aria-role": "listitem"
                                              }
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "media" },
                                                [
                                                  _c("b-icon", {
                                                    staticClass: "media-left",
                                                    attrs: { icon: "view-list" }
                                                  }),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "media-content"
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "dbi-heading"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                        Reviewer Projects\n                      "
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "dbi-description"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                        Projects that can be Reviewed Only\n                      "
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.showButton("search")
                                ? _c(
                                    "div",
                                    { staticClass: "search-container" },
                                    [
                                      _c(
                                        "b-field",
                                        [
                                          _c("b-input", {
                                            ref: "search",
                                            staticClass: "search-input",
                                            attrs: {
                                              type: "text",
                                              placeholder: "Search Projects",
                                              value: _vm.projectOptions.search,
                                              "icon-right": _vm.projectOptions
                                                .search
                                                ? "magnify-close"
                                                : "magnify",
                                              "icon-right-clickable": ""
                                            },
                                            on: {
                                              "icon-right-click":
                                                _vm.clearSearch,
                                              input: function(value) {
                                                return _vm.searchProjects(
                                                  value,
                                                  _vm.projectOptions.searchField
                                                )
                                              }
                                            }
                                          }),
                                          _vm._v(" "),
                                          _vm.isProjectOwner &&
                                          !_vm.isActingAsReviewer
                                            ? _c(
                                                "b-dropdown",
                                                {
                                                  attrs: {
                                                    position: "is-bottom-left"
                                                  },
                                                  on: {
                                                    input: function(value) {
                                                      return _vm.searchProjects(
                                                        _vm.projectOptions
                                                          .search,
                                                        value
                                                      )
                                                    }
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "trigger",
                                                        fn: function() {
                                                          return [
                                                            _c(
                                                              "b-button",
                                                              {
                                                                staticClass:
                                                                  "btn-search-field",
                                                                attrs: {
                                                                  variant:
                                                                    "projects"
                                                                }
                                                              },
                                                              [
                                                                _c("b-icon", {
                                                                  attrs: {
                                                                    icon:
                                                                      "chevron-down"
                                                                  }
                                                                })
                                                              ],
                                                              1
                                                            )
                                                          ]
                                                        },
                                                        proxy: true
                                                      }
                                                    ],
                                                    null,
                                                    false,
                                                    4215915293
                                                  ),
                                                  model: {
                                                    value: _vm.searchField,
                                                    callback: function($$v) {
                                                      _vm.searchField = $$v
                                                    },
                                                    expression: "searchField"
                                                  }
                                                },
                                                [
                                                  _vm._v(" "),
                                                  _c(
                                                    "b-dropdown-item",
                                                    { attrs: { custom: "" } },
                                                    [
                                                      _vm._v(
                                                        "\n                      Search By:\n                    "
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("hr", {
                                                    staticClass:
                                                      "dropdown-divider"
                                                  }),
                                                  _vm._v(" "),
                                                  _c(
                                                    "b-dropdown-item",
                                                    {
                                                      attrs: { value: "name" }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                      Project Name\n                    "
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "b-dropdown-item",
                                                    {
                                                      attrs: {
                                                        value: "description"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                      Description\n                    "
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "b-dropdown-item",
                                                    {
                                                      attrs: { value: "tags" }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                      Tags\n                    "
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }