var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "page-wrap-outer theme-marketing",
      class: { mobile: _vm.isMobile }
    },
    [
      _c("AuthModal", { attrs: { "form-to-load": _vm.initialAuthForm } }),
      _vm._v(" "),
      _c("ReadEmailModal", { attrs: { message: _vm.emailReferralMsg } }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "page-wrap",
          class: { mobile: _vm.isMobile, "nav-open": _vm.navOpen }
        },
        [
          _c(
            "div",
            [
              _vm.isMobile
                ? _c(
                    "b-container",
                    {
                      staticClass: "header sticky-top",
                      class: { "sticky-header": _vm.stickyHeader },
                      attrs: { fluid: "" }
                    },
                    [
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            { staticClass: "nav-logo" },
                            [
                              _c("b-navbar-brand", { attrs: { to: "/" } }, [
                                _c("img", {
                                  attrs: {
                                    src: require("assets/images/LassoLogoColorTM-NoTagline.png")
                                  }
                                })
                              ])
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "b-col",
                            {
                              staticClass: "nav-toggle",
                              on: {
                                click: function($event) {
                                  return _vm.toggleMenu([])
                                }
                              }
                            },
                            [_c("IconHamburger")],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.isMobile
                ? _c("NavMobile", {
                    attrs: { "nav-open": _vm.navOpen },
                    on: { handleLoginRegisterClick: _vm.setInitialAuthModal }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.isDesktop
                ? _c("Header", {
                    class: { "sticky-header": _vm.stickyHeader },
                    attrs: {
                      loading: _vm.loading,
                      "layout-type": "marketing",
                      "show-email-btn": _vm.emailReferral
                    },
                    on: { handleLoginRegisterClick: _vm.setInitialAuthModal }
                  })
                : _vm._e(),
              _vm._v(" "),
              !_vm.loading
                ? _c("nuxt", {
                    staticClass: "page-content",
                    class: _vm.pageTheme
                  })
                : _vm._e()
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      !_vm.navOpen ? _c("Footer") : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }