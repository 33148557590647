//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters } from 'vuex'
import { userRoles } from '@/server/constants'

const userRolesAll = userRoles.participant + userRoles.projectowner + userRoles.admin

export default {
  name: 'Header',
  props: {
    layoutType: {
      type: String,
      default: 'marketing'
    },
    loading: {
      type: Boolean,
      default: false
    },
    showEmailBtn: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      logoAnimation: false
    }
  },
  computed: {
    ...mapGetters({
      loggedInUser: 'user/loggedInUser',
      userFullName: 'user/fullName',
      impersonationUserId: 'user/getImpersonationUserId',
      isCollaborator: 'user/isCollaborator',
      isActingAsReviewer: 'user/isActingAsReviewer',
      isAdmin: 'user/isAdmin',
      isReallyASuperAdmin: 'user/isReallyASuperAdmin',
      user: 'user/user',
      pageTheme: 'global/pageTheme'
    }),
    showStopImpersonation() {
      return this.impersonationUserId && (!this.isCollaborator || this.isReallyASuperAdmin)
    },
    mainNavItems() {
      const mapViewerSecurity = userRoles.projectowner
      const navItems = [
        { page: 'dashboard', security: userRolesAll },
        { page: 'inbox', security: userRolesAll },
        { page: 'projects', security: userRolesAll },
        { text: 'LOIS Viewer', target: '_blank', page: 'mapviewer', security: mapViewerSecurity, ignore: this.isActingAsReviewer },
        { page: 'templates', security: userRoles.projectowner, ignore: this.isActingAsReviewer },
        { page: 'reports', security: userRoles.projectowner, ignore: this.isActingAsReviewer },
        { page: 'admin', security: userRoles.admin }
      ]
      return navItems
    },
    isMarketingTheme() {
      return this.pageTheme === 'marketing'
    },
    userInitials() {
      const name = this.userFullName.toUpperCase().split(' ')
      let initials = name.map(i => i.charAt(0))
      initials = initials.join('')
      return initials
    }
  },
  mounted() {
    this.logoAnimation = JSON.parse(localStorage.getItem('logoanimation'))
    if (this.logoAnimation) {
      return
    }
    this.$nextTick(() => {
      setTimeout(() => {
        localStorage.setItem('logoanimation', true)
        this.logoAnimation = true
      }, 1000)
    })
  },
  methods: {
    ...mapActions({
      logout: 'user/logout',
      getOrg: 'user/fetchUserOrg',
      logoutImpersonation: 'user/logoutImpersonatedUser'
    }),
    async logOutImpersonatedUser() {
      this.$router.push('/projects')
      await new Promise((resolve) => {
        setTimeout(async () => {
          await this.logoutImpersonation()
          // It might be better to redirect to the /admin or /dashboard
          // and support a imp=logout or something like this
          this.$router.push('/admin')
          return resolve()
        }, 200)
      })
    },
    async logMeOut() {
      if (this.user.oid) {
        // If user is sso oauth logout
        const org = await this.getOrg()
        const url = `${org.oauth2LogoutUrl}`
        // Removes all client side cookies and settings
        await this.logout(true)
        // Redirect to the IdP logout url
        return this.$router.push(url)
      }
      this.$router.push('/')
      await this.logout(false)
    },
    openAuthModal(modalType) {
      this.$emit('handleLoginRegisterClick', modalType)
      this.$bvModal.show('modal-auth')
    }
  }
}
