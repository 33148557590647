//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { isEmpty } from 'lodash-es'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'AdminLayout',
  data () {
    return {
      pageTheme: ''
    }
  },
  computed: {
    ...mapGetters({
      currentTheme: 'global/pageTheme',
      error: 'global/networkError',
      stack: 'global/networkErrorStack',
      isDesktop: 'breakpoints/desktop',
      isMobile: 'breakpoints/mobile',
      navOpen: 'global/navOpen',
      user: 'user/getActiveUser'
    }),
    pageBreadcrumbs() {
      return this.pageMeta?.breadcrumbs || []
    },
    pageName() {
      return this.pageMeta?.name || 'Admin'
    },
    pageMeta() {
      const pages = [
        {
          name: 'User Details',
          routeName: 'admin-userid',
          breadcrumbs: [
          ]
        },
        {
          name: 'Add User',
          routeName: 'admin-add',
          breadcrumbs: [
          ]
        },
        {
          name: 'Edit User',
          routeName: 'admin-userid-edit',
          breadcrumbs: [
          ]
        },
        {
          // name: 'User List',
          name: 'Admin',
          routeName: 'admin',
          breadcrumbs: [
          ]
        },
        {
          name: 'Organization Details',
          routeName: 'admin-organizations-organizationid',
          breadcrumbs: [
          ]
        },
        {
          name: 'Add Organization',
          routeName: 'admin-organizations-add',
          breadcrumbs: [
          ]
        },
        {
          name: 'Edit Organization',
          routeName: 'admin-organizations-organizationid-edit',
          breadcrumbs: [
          ]
        },
        {
          name: 'Organization List',
          routeName: 'admin-organizations',
          breadcrumbs: [
          ]
        },
        {
          name: 'Data Feed Details',
          routeName: 'admin-datafeeds-datafeedid',
          breadcrumbs: [
          ]
        },
        {
          name: 'Add Data Feed',
          routeName: 'admin-datafeeds-add',
          breadcrumbs: [
          ]
        },
        {
          name: 'Edit Data Feed',
          routeName: 'admin-datafeeds-datafeedid-edit',
          breadcrumbs: [
          ]
        },
        {
          name: 'Data Feed List',
          routeName: 'admin-datafeeds',
          breadcrumbs: [
          ]
        }
      ]
      const meta = pages.filter(p => p.routeName === this.$route.name)
      return meta[0]
    },
    showFullPageLayout() {
      return ['admin', 'admin-add', 'admin-userid-edit'].includes(this.$route.name)
    },
    showError() {
      return this.error && this.error?.info?.status !== 401
    }
  },
  watch: {
    $route(newVal) {
      if (newVal.params.userid) {
        this.setPageTheme()
        if (!['admin', 'admin-add'].includes(this.$route.name)) {
          this.setUserId()
        }
      }
    }
  },
  mounted () {
    this.setPageTheme()
    this.watchBreakpoints()
    if (!['admin', 'admin-add'].includes(this.$route.name)) {
      this.setUserId()
    }
  },
  methods: {
    ...mapActions({
      setActiveUserId: 'user/setActiveUserId',
      setTheme: 'global/setPageTheme',
      toggleMenu: 'global/toggleMenu',
      watchBreakpoints: 'breakpoints/watch'
    }),
    setUserId() {
      if (isEmpty(this.user)) {
        this.setActiveUserId(this.$route.params.userid)
      }
    },
    setPageTheme () {
      const theme = this.$route.path.match(/dashboard|inbox|projects|reports|admin|templates/gi)
      if (theme) {
        this.pageTheme = `theme-${theme[0]}`
        this.setTheme(theme[0])
      } else {
        this.pageTheme = 'theme-dashboard'
        this.setTheme('dashboard')
      }
    }
  }
}
