//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters } from 'vuex'
import draggable from 'vuedraggable'

export default {
  name: 'QuestionDetailView',
  components: {
    draggable
  },
  props: {
    // normal = full owner capabilities or readonly = questions can be viewed by participants but not answered
    mode: {
      type: String,
      default: 'normal'
    }
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters({
      activeCategory: 'questions/getActiveCategory',
      lastQuestionDeleted: 'questions/getLastQuestionDeleted',
      primaryCategory: 'questions/getPrimaryCategory',
      isActingAsReviewer: 'user/isActingAsReviewer'
    }),
    calculatedMode() {
      return this.isActingAsReviewer ? 'readonly' : this.mode
    },
    questionList: {
      get() {
        return this.$store.state.questions.questions
      },
      set(questionList) {
        this.updateQuestionList(questionList)
      }
    }
  },
  watch: {
    lastQuestionDeleted(newVal) {
      if (newVal) {
        this.refreshCategories()
      }
    }
  },
  mounted() {
    this.route = { ...this.$route.params }
    this.categoryOwner = this.route.templateid ? this.route.templateid : this.route.projectid
    this.categoryType = this.route.templateid ? 1 : 2
    if (this.isActingAsReviewer) {
      this.mode = 'readonly'
    }
  },
  methods: {
    ...mapActions({
      fetchPrimaryCategories: 'questions/fetchPrimaryCategories',
      setLastQuestionDeleted: 'questions/setLastQuestionDeleted',
      updateQuestionList: 'questions/updateQuestionList'
    }),
    async refreshCategories() {
      await this.fetchPrimaryCategories({ owner: this.categoryOwner, type: this.categoryType })
      this.setLastQuestionDeleted(false)
      localStorage.removeItem('categoryselections')
      if (this.activeCategory === this.primaryCategory) {
        /*
          TODO: 2 issues:
          1) if primary deleted and there were NO secondaries under that category, we should
          update the localstorage to Uncategories and set the active category

          2) Bug exists if primary deleted and there ARE secondary categories associated. Secondaries
          are briefly showing up under Uncategorized, but they are not really there and once you
          start changing categories they disappear as secondaries under Uncategorized. At that point,
          you can't access the questions in these secondaries either. They become orphans unaccessible
          in CategoryQuestions. This is because they have no primary but still have a secondary assigned.
          I found this by going to Requirements tab and seeing some of the questions and was able to access
          them that way but there are likely alot of orphans questions in the db now with a secondary but no primary.
        */
      }
    }
  }
}
