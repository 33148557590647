var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "footer",
    { staticClass: "main-footer" },
    [
      _c(
        "b-container",
        { staticClass: "mobile-user-nav", attrs: { fluid: "" } },
        [
          _c(
            "b-container",
            [
              _vm.loggedInUser && !_vm.isDesktop && !_vm.isMarketingTheme
                ? _c(
                    "b-row",
                    { attrs: { "no-gutters": "" } },
                    [
                      _c(
                        "b-col",
                        [
                          _c("span", { staticClass: "section-header" }, [
                            _vm._v("MENU")
                          ]),
                          _vm._v(" "),
                          _c("NavItems", {
                            staticClass: "small",
                            attrs: {
                              items: _vm.selectedNav,
                              "display-type": _vm.navStyle
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-container",
        { staticClass: "strata-footer", attrs: { fluid: "" } },
        [
          _c(
            "b-row",
            { attrs: { "no-gutters": "" } },
            [
              _c(
                "b-col",
                { staticClass: "strata", attrs: { cols: "8" } },
                [
                  _c(
                    "b-link",
                    { attrs: { href: "http://strataplatforms.com" } },
                    [
                      _vm.isDesktop
                        ? _c("img", {
                            staticClass: "logo-lg",
                            attrs: {
                              src: require("assets/images/StrataLogo_White_Horiz.png"),
                              alt: "Strata Platforms Logo"
                            }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.isDesktop
                        ? _c("img", {
                            staticClass: "logo-md",
                            attrs: {
                              src: require("assets/images/StrataLogo_White_Vert.png"),
                              alt: "Strata Platforms Logo"
                            }
                          })
                        : _vm._e()
                    ]
                  ),
                  _vm._v(" "),
                  _c("p", { staticClass: "rights-reserved" }, [
                    _vm._v(
                      "\n          Operating globally from Greenville, SC, USA\n        "
                    )
                  ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "rights-reserved" }, [
                    _vm._v(
                      "\n          @2025 Strata Platforms. All Rights Reserved.\n        "
                    )
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-col",
                { staticClass: "info", attrs: { cols: "4" } },
                [
                  _c("span", { staticClass: "section-header" }, [
                    _vm._v("INFO")
                  ]),
                  _vm._v(" "),
                  _c(
                    "b-link",
                    { staticClass: "info-link", attrs: { to: "/support" } },
                    [_vm._v("\n          Support\n        ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-link",
                    { staticClass: "info-link", attrs: { to: "/privacy" } },
                    [_vm._v("\n          Privacy Policy\n        ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-link",
                    { staticClass: "info-link", attrs: { to: "/terms" } },
                    [_vm._v("\n          Terms of Use\n        ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }