export const state = () => ({
  activeDataFeed: {},
  dataFeeds: [],
  dataFeedsCount: 0,
  adminDataFeedOptions: {
    filter: 'all',
    sort: '',
    limit: 10,
    skip: 0,
    search: '',
    page: 0
  }
})

export const getters = {
  getDataFeeds: state => state.dataFeeds,
  getActiveDataFeed: state => state.activeDataFeed,
  getDataFeedCount: state => state.dataFeedCount,
  getAdminDataFeedOptions: state => state.adminDataFeedOptions
}

export const actions = {
  async addDataFeed({ commit, dispatch }, df) {
    try {
      const { result, dataFeed } = await this.$axios.$post('/datafeed', df)
      if (result === 'ok') {
        commit('addNewDataFeed', dataFeed)
        return dataFeed
      }
      return false
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'dataFeeds/addDataFeed', info: err },
        { root: true }
      )
    }
  },

  async fetchDataFeeds({ commit, state, dispatch }) {
    const {
      filter,
      sort,
      limit,
      page,
      search
    } = state.adminDataFeedOptions
    const skip = page * limit
    const params = [
      filter ? `filter=${filter}` : '',
      sort ? `sortby=${sort}` : '',
      limit ? `limit=${limit}` : '',
      skip ? `skip=${skip}` : '',
      search ? `search=${search}` : ''
    ]
    const url = '/datafeeds?' + params.filter(p => p).join('&')
    try {
      const dataFeeds = await this.$axios.$get(url)
      commit('setDataFeeds', dataFeeds.feeds)
      commit('setDataFeedCount', dataFeeds.count)
      return true
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'dataFeeds/fetchDataFeeds', info: err },
        { root: true }
      )
    }
  },

  async getDataFeedById({ commit, dispatch }, dfId) {
    try {
      const result = await this.$axios.$get(`/datafeed/${dfId}`)
      commit('setActiveDataFeed', result.dataFeed)
      return result.dataFeed
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'dataFeeds/getDataFeedById', info: err },
        { root: true }
      )
    }
  },

  async userHasDataFeeds({ dispatch }, params = {}) {
    try {
      const { projectId = undefined } = params
      const { result } = await this.$axios.$get(`/datafeeds/me/has/${projectId}`)
      return result
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'dataFeeds/hasDataFeeds', info: err },
        { root: true }
      )
    }
  },

  setActiveDataFeed({ commit }, df) {
    commit('setActiveDataFeed', df)
  },

  setSearchTerm({ commit }, searchTerm) {
    commit('setSearchTerm', searchTerm)
  },

  async removeDataFeed({ commit, dispatch }, dfId) {
    try {
      const { result } = await this.$axios.$delete(`/datafeed/${dfId}`)
      if (result === 'ok') {
        commit('removeDataFeed', dfId)
      }
      return result === 'ok'
    } catch (error) {
      return dispatch(
        'global/setNetworkError',
        { method: 'dataFeeds/removeDataFeed', info: error },
        { root: true }
      )
    }
  },

  async updateDataFeed({ commit, dispatch }, df) {
    try {
      const { result, dataFeed } = await this.$axios.$put(`/datafeed/${df._id}`, df)
      if (result === 'ok') {
        commit('updateDataFeed', dataFeed)
        return dataFeed
      }
      return false
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'dataFeeds/updateDataFeed', info: err },
        { root: true }
      )
    }
  }

}

export const mutations = {
  init(state) {
    state.activeDataFeed = {}
    state.dataFeeds = []
    state.adminDataFeedOptions = {
      filter: 'all',
      sort: '',
      limit: 10,
      skip: 0,
      search: '',
      page: 0
    }
  },
  resetOptions(state) {
    state.adminDataFeedOptions = {
      filter: 'all',
      sort: '',
      limit: 10,
      skip: 0,
      search: '',
      page: 0
    }
  },
  addNewDataFeed(state, df) {
    state.activeDataFeed = df
    state.dataFeeds = state.dataFeeds.push(df)
  },
  setActiveDataFeed(state, df) {
    state.activeDataFeed = df
  },
  setDataFeeds(state, dataFeeds) {
    state.dataFeeds = dataFeeds
  },
  setDataFeedCount(state, count) {
    state.dataFeedCount = count
  },
  setSearchTerm(state, search) {
    state.adminDataFeedOptions.page = 0
    state.adminDataFeedOptions.search = search
  },
  updateDataFeed(state, df) {
    state.activeDataFeed = df
    state.dataFeeds = state.dataFeeds
      .map(d => String(d._id) === String(df._id) ? df : d)
  },
  removeDataFeed(state, dfId) {
    state.dataFeeds = state.dataFeeds.filter(df => String(df._id) !== String(dfId))
    state.activeDataFeed = null
  }
}
