export const state = () => ({
  fetchingMessages: false,
  listMessageId: '',
  messageListIndex: -1,
  messageList: [],
  messageListCount: 0,
  messageRecsRetrieved: 0,
  viewMessageIndex: 0,
  activeMessageFull: [],
  availableRecipients: [],
  folderOptions: [
    { label: 'Inbox', value: 'inbox' },
    { label: 'Drafts', value: 'drafts' },
    { label: 'Archived', value: 'archived' },
    { label: 'Sent', value: 'sent' },
    { label: 'Trash', value: 'trash' }
  ],
  inboxOptions: {
    activeMessageFilter: 'all',
    activeMessageFolder: 'inbox',
    activeMessageSort: 'newest',
    messageListLimit: '50',
    messageListSkip: '',
    messageSearchTerm: '',
    messagePage: 0
  },
  messageBuyLicenses: {},
  messageParticipant: {
    email: '',
    projectid: ''
  },
  messageAllParticipants: {
    emails: [],
    projectid: '',
    active: false
  },
  messageProjectOwner: {
    email: '',
    projectid: ''
  },
  messageFormMode: '',
  referralMessageId: '',
  referredNonAuthUser: '',
  selectedMessages: {
    inbox: [],
    drafts: [],
    sent: [],
    archived: [],
    trash: []
  },
  showMessageForm: false,
  showMessageFormInThread: false,
  dropDownInUse: false,
  projectList: [],
  unreadMessageCount: 0,
  unreadMessageCountFails: 0
})

export const getters = {
  getActiveMessageFilter: state => state.inboxOptions.activeMessageFilter,
  getActiveMessageFolder: state => state.inboxOptions.activeMessageFolder,
  getActiveMessageFull: state => state.activeMessageFull,
  getActiveMessageSort: state => state.inboxOptions.activeMessageSort,
  getActiveMessageSubject: state => state.activeMessageFull?.length ? state.activeMessageFull[0].subject : '',
  getActiveMessageThreadCount: state => state.activeMessageFull.length,
  getAvailableRecipients: state => state.availableRecipients,
  getFolderOptions: state => state.folderOptions,
  getDropDownInUse: state => state.dropDownInUse,
  getInboxOptions: state => state.inboxOptions,
  getListMessageId: state => state.listMessageId,
  getMessageList: state => state.messageList,
  getMessageListCount: state => state.messageListCount,
  getMessageListIndex: state => state.messageListIndex,
  getMessageFormMode: state => state.messageFormMode,
  getMessageListLimit: state => state.messageListLimit,
  getMessageListSkip: state => state.messageListSkip,
  getMessageBuyLicenses: state => state.messageBuyLicenses,
  getMessageParticipant: state => state.messageParticipant,
  getMessageAllParticipants: state => state.messageAllParticipants,
  getMessageProjectOwner: state => state.messageProjectOwner,
  getMessageSearchTerm: state => state.inboxOptions.messageSearchTerm,
  getProjectList: state => state.projectList,
  getReferralMessageId: state => state.referralMessageId,
  getReferredNonAuthUser: state => state.referredNonAuthUser,
  getSelectedMessages: state => state.selectedMessages[state.inboxOptions.activeMessageFolder],
  getShowMessageForm: state => state.showMessageForm,
  getShowMessageFormInThread: state => state.showMessageFormInThread,
  getUnreadMessageCount: state => state.unreadMessageCount,
  getViewMessageIndex: state => state.viewMessageIndex,
  getFetchingMessages: state => state.fetchingMessages
}

export const actions = {

  // requires the message._id and form data ready to send to server
  async addAttachment({ dispatch }, { file, imageData, embedded = false }) {
    try {
      let attachment
      if (file) {
        const data = new FormData()
        data.append('attachment', file)
        data.append('embedded', embedded)
        attachment = await this.$axios.$post('/attachment', data, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
      } else {
        attachment = await this.$axios.$post('/attachment', { imageData, embedded })
      }
      if (!attachment) {
        const e = new Error('No attachment data')
        e.response = 'No attachment data'
        throw e
      }
      return attachment
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'messages/addAttachment', info: err },
        { root: true })
    }
  },

  addSelectedMessageId({ commit }, id) {
    commit('addSelectedMessageId', id)
  },

  async archiveMessage({ state, dispatch }, messageId) {
    try {
      const fromFolder = state.inboxOptions.activeMessageFolder
      await this.$axios.$put('/message/folder/archived', { messageId, fromFolder })
      dispatch('setMessageListIndex', { messageId })
      await dispatch('fetchMessagesByFolder')
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'messages/archiveMessage', info: err },
        { root: true })
    }
  },

  async archiveSelectedMessages({ commit, dispatch, state }) {
    try {
      const fromFolder = state.inboxOptions.activeMessageFolder
      for (const _id of state.selectedMessages[fromFolder]) {
        await this.$axios.$put('/message/folder/archived', { _id, fromFolder })
      }
      // sets the messageList selected to first message
      commit('setMessageListIndex', -1)
      await dispatch('fetchMessagesByFolder')
      dispatch('clearSelectedMessages')
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'messages/archiveSelectedMessages', info: err },
        { root: true })
    }
  },

  clearSelectedMessages({ commit }) {
    commit('clearSelectedMessages')
  },

  async deleteMessage({ commit, dispatch }, { _id }) {
    try {
      await this.$axios.$put('/message/folder/trash', { _id })
      dispatch('setMessageListIndex', { _id })
      await dispatch('fetchMessagesByFolder')
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'messages/deleteMessages', info: err },
        { root: true })
    }
  },

  async deleteSelectedMessages({ commit, dispatch, state }) {
    try {
      const fromFolder = state.inboxOptions.activeMessageFolder
      for (const _id of state.selectedMessages[fromFolder]) {
        await this.$axios.$put('/message/folder/trash', { _id, fromFolder })
      }
      commit('setMessageListIndex', -1)
      await dispatch('fetchMessagesByFolder')
      dispatch('clearSelectedMessages')
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'messages/deleteSelectedMessages', info: err },
        { root: true })
    }
  },

  async emptyTrash({ commit, dispatch }) {
    try {
      await this.$axios.$delete('/messages/delete/all')
      commit('setMessageListIndex', -1)
      await dispatch('fetchMessagesByFolder')
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'messages/emptyTrash', info: err },
        { root: true })
    }
  },

  async fetchMessagesByFolder({ state, commit, dispatch }) {
    const {
      activeMessageFilter,
      activeMessageFolder,
      activeMessageSort,
      messageListLimit,
      messageSearchTerm,
      messagePage
    } = state.inboxOptions
    const messageListSkip = messagePage * messageListLimit
    const sortParams = () => {
      const sort = state.inboxOptions.activeMessageSort
      if (sort === 'newest') {
        return 'sortby=-sent+-created'
      } else if (sort === 'oldest') {
        return 'sortby=sent+created'
      } else {
        return `sortby=${state.inboxOptions.activeMessageSort}`
      }
    }
    const params = [
      activeMessageFilter || activeMessageFilter === 0 ? `filter=${activeMessageFilter}` : '',
      activeMessageSort ? sortParams() : '',
      messageListLimit ? `limit=${messageListLimit}` : '',
      messageListSkip ? `skip=${messageListSkip}` : '',
      messageSearchTerm ? `search=${messageSearchTerm}` : ''
    ]
    try {
      const res = await this.$axios.$get(`/messages/${activeMessageFolder}?` + params.filter(p => p).join('&'))
      commit('setMessageList', res.messages)
      commit('setMessageListCount', res.count)
      dispatch('fetchFolderOptions')
      // Determine which message should be selected
      if (res.messages.length > 0) {
        if (!state.fetchingMessages) {
          const _id = state.messageListIndex > -1 ? res.messages[state.messageListIndex]._id : res.messages[0]._id
          dispatch('setListMessageId', _id)
        }
      } else {
        // if the messageList is empty everything should be reset
        commit('setListMessageId', '')
        commit('setViewMessageIndex', 0)
        commit('setActiveMessageFull', [])
      }
      commit('setMessageListIndex', -1)
      commit('setFetchingMessages', false)
    } catch (err) {
      console.error(err)
      commit('setFetchingMessages', false)
      return dispatch(
        'global/setNetworkError',
        { method: 'messages/fetchMessagesByFolder', info: err },
        { root: true })
    }
  },

  async fetchFolderOptions({ commit, dispatch }) {
    try {
      const res = await this.$axios.$get('/message/count')
      commit('setFolderOptions', res)
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'messages/fetchFolderOptions', info: err },
        { root: true })
    }
  },

  async fetchMessageById({ commit, dispatch }, id) {
    try {
      const message = await this.$axios.$get(`/message?${id}`)
      commit('setActiveMessageFull', message)
      commit('setViewMessageIndex', 0)
      return message
    } catch (err) {
      /* return dispatch(
        'global/setNetworkError',
        { method: 'messages/fetchMessageById', info: err },
        { root: true })
      */
      console.error('fetchMessageById error:', err)
    }
  },

  async fetchProjectList({ commit, dispatch }) {
    try {
      const projects = await this.$axios.$get('/message/projects')
      commit('setProjectList', projects)
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'messages/fetchProjectList', info: err },
        { root: true })
    }
  },

  async fetchUserByEmail({ commit, dispatch }, email) {
    try {
      const users = await this.$axios.$get(`/user/email/search?email=${email}`)
      commit('setAvailableRecipients', users)
      return users
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'user/fetchUserByEmail', err },
        { root: true })
    }
  },

  async fetchUnreadMessageCount({ state, commit, dispatch }) {
    try {
      const res = await this.$axios.$get('/messages/unread/count', { timeout: 5000 })
      commit('setUnreadMessageCount', parseInt(res.count || 0))
      commit('unreadMessageCountFailsZero')
      return parseInt(res.count)
    } catch (err) {
      commit('unreadMessageCountFailsIncrement')
      if (state.unreadMessageCountFails > 2) {
        await dispatch('user/logout', null, { root: true })
      }
      return 0
    }
  },

  // get message for non-auth users
  async getEmailReferralMsg({ commit, dispatch }, id) {
    try {
      const res = await this.$axios.$get(`/message/noauth/${id}`)
      return res
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'messages/getEmailReferralMsg', info: err },
        { root: true })
    }
  },

  // get id of message from email referral id
  async getMessageIdFromEmailReferralId({ commit, dispatch }, id) {
    try {
      return await this.$axios.$get(`/message/messageid/${id}`)
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'messages/getMessageIdFromEmailReferralId', info: err },
        { root: true })
    }
  },

  incrementMessageListPage({ commit }) {
    commit('setMessageListPage')
  },

  async moveMessageToInbox({ state, commit, dispatch }, _id) {
    try {
      // This should only be allowed for messages currently in the 'archived'
      // folder, and if attempted from a different folder, the request will
      // be rejected. The messageId cannot be used here, so please use _id.
      const fromFolder = state.inboxOptions.activeMessageFolder
      await this.$axios.$put('/message/folder/inbox', { _id, fromFolder })
      dispatch('setMessageListIndex', { _id })
      await dispatch('fetchMessagesByFolder')
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'messages/moveMessageToInbox', info: err },
        { root: true })
    }
  },

  async moveSelectedMessagesToInbox({ commit, dispatch, state }) {
    try {
      // This should only be allowed for messages currently in the 'archived'
      // folder, and if attempted from a different folder, the request will
      // be rejected.
      const fromFolder = state.inboxOptions.activeMessageFolder
      for (const _id of state.selectedMessages[fromFolder]) {
        await this.$axios.$put('/message/folder/inbox', { _id, fromFolder })
      }
      commit('setMessageListIndex', -1)
      await dispatch('fetchMessagesByFolder')
      dispatch('clearSelectedMessages')
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'messages/moveSelectedMessagesToInbox', info: err },
        { root: true })
    }
  },

  async permanentDeleteMessage({ commit, dispatch, state }, id) {
    try {
      const fromFolder = state.inboxOptions.getActiveMessageFolder
      await this.$axios.$delete(`/message/delete?id=${id}&fromFolder=${fromFolder}`)
      dispatch('setMessageListIndex', { _id: id })
      await dispatch('fetchMessagesByFolder')
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'messages/permanentDeleteMessage', info: err },
        { root: true })
    }
  },

  async permanentDeleteSelectedMessages({ commit, dispatch, state }) {
    try {
      const fromFolder = state.inboxOptions.activeMessageFolder
      for (const _id of state.selectedMessages[fromFolder]) {
        await this.$axios.$delete(`/message/delete?id=${_id}&fromFolder=${fromFolder}`)
      }
      commit('setMessageListIndex', -1)
      await dispatch('fetchMessagesByFolder')
      dispatch('clearSelectedMessages')
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'messages/permanentDeleteSelectedMessages', info: err },
        { root: true })
    }
  },

  removeSelectedMessageId({ commit }, id) {
    commit('removeSelectedMessageId', id)
  },

  async removeAttachment({ dispatch }, { _id }) {
    try {
      await this.$axios.delete(`/attachment/${_id}`)
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'messages/removeAttachment', info: err },
        { root: true })
    }
  },

  async restoreMessage({ dispatch, state }, _id) {
    try {
      // restoreMessage can only occur from the 'trash' folder, otherwise the
      // request to restore will be rejected.  Although this says 'inbox' as
      // the folder to restore to, the message will actually be restored to
      // it's previousFolder value.  This function does require the _id value
      // and not the messageId
      const fromFolder = state.inboxOptions.activeMessageFolder
      await this.$axios.$put('/message/folder/inbox', { _id, fromFolder })
      dispatch('setMessageListIndex', { _id })
      await dispatch('fetchMessagesByFolder')
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'messages/restoreMessage', info: err },
        { root: true })
    }
  },

  async restoreSelectedMessages({ commit, dispatch, state }) {
    try {
      // restoreSelectedMessages can only occur in the 'trash' folder, otherwise
      // the request to restore will be rejected.  Although this says 'inbox' as
      // the folder to restore to, the messages will actually be restored to
      // their value of previousFolder.
      const fromFolder = state.inboxOptions.activeMessageFolder
      for (const _id of state.selectedMessages[fromFolder]) {
        await this.$axios.$put('/message/folder/inbox', { _id, fromFolder })
      }
      commit('setMessageListIndex', -1)
      await dispatch('fetchMessagesByFolder')
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'messages/restoreSelectedMessages', info: err },
        { root: true })
    }
  },

  async saveDraft({ commit, dispatch }, message) {
    try {
      const messageDraft = await this.$axios.$post('/message', message)
      return messageDraft
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'messages/saveDraft', info: err },
        { root: true })
    }
  },

  async sendMessage({ commit, dispatch }, id) {
    try {
      const res = await this.$axios.$post(`/message/${id}`)
      dispatch('setMessageListIndex', { stay: true })
      dispatch('fetchMessagesByFolder')
      return res.messageId
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'messages/sendMessage', info: err },
        { root: true })
    }
  },

  setActiveMessageFilter({ commit }, filter) {
    commit('setActiveMessageFilter', filter)
  },

  setActiveMessageFolder({ commit }, folder) {
    commit('setMessageListPage', 0)
    commit('setActiveMessageFolder', folder)
  },

  setActiveMessageSort({ commit }, order) {
    commit('setActiveMessageSort', order)
  },

  setDropDownInUse({ commit }, status) {
    commit('dropDownInUse', status)
  },

  async setListMessageId({ commit, dispatch }, id) {
    try {
      commit('setListMessageId', id)
      await dispatch('fetchMessageById', `id=${id}`)
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'messages/setListMessageId call to fetchMessageId', info: err },
        { root: true })
    }
  },

  setMessageFormMode({ commit }, mode) {
    commit('setMessageFormMode', mode)
  },

  // Should calculate the best message to select in the messageList after a message
  // has been deleted or archived or moved, etc.
  setMessageListIndex({ state, commit }, { messageId, _id, stay }) {
    let newIndex
    let selectedListIndex = state.messageList.findIndex(m => m._id === state.listMessageId)
    if (stay) {
      if (selectedListIndex >= state.messageList.length) {
        selectedListIndex = state.messageList.length - 1
      }
      commit('setMessageListIndex', selectedListIndex)
      return
    }
    const newLen = state.messageList.length - 1
    if (newLen <= 0) {
      commit('setMessageListIndex', -1)
      return
    }
    const targetListIndex = messageId
      ? state.messageList.findIndex(m => m.messageId === messageId)
      : state.messageList.findIndex(m => m._id === _id)
    if (targetListIndex === -1) {
      if (selectedListIndex >= state.messageList.length) {
        selectedListIndex = 0
      }
      commit('setMessageListIndex', selectedListIndex)
      return
    }
    if (selectedListIndex === targetListIndex) {
      if (selectedListIndex - 1 >= 0) {
        newIndex = selectedListIndex - 1
      } else {
        newIndex = selectedListIndex
      }
    } else if (selectedListIndex < targetListIndex) {
      newIndex = selectedListIndex
    } else {
      newIndex = selectedListIndex + 1 >= newLen ? selectedListIndex : selectedListIndex + 1
    }
    if (newIndex >= state.messageList.length) {
      newIndex = 0
    }
    commit('setMessageListIndex', newIndex)
  },

  setMessageListLimit({ commit }, limit) {
    commit('setMessageListLimit', limit)
  },

  setMessageListSkip({ commit }, skip) {
    commit('setMessageListSkip', skip)
  },

  setMessageParticipant({ commit }, participant) {
    commit('setMessageParticipant', participant)
  },

  async setMessageProjectOwner({ commit, dispatch }, { projectid }) {
    try {
      const { email } = await this.$axios.$get(`/project/${projectid}/owner/email`)
      commit('setMessageProjectOwner', { email, projectid })
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'messages/setMessageProjectOwner', info: err },
        { root: true })
    }
  },

  setMessageAllParticipants({ commit }, participants, status) {
    commit('setMessageAllParticipants', participants, status)
  },

  // redirect to inbox from licenses unavailable modal
  setMessageBuyLicenses({ commit }, { type, org }) {
    commit('setMessageBuyLicenses', { type, org })
  },

  setMessageSearchTerm({ commit }, search) {
    commit('setMessageSearchTerm', search)
  },

  setReferralMessageId({ commit }, id) {
    commit('setReferralMessageId', id)
  },

  setReferredNonAuthUser({ commit }, email) {
    commit('setReferredNonAuthUser', email)
  },

  setShowMessageForm({ commit }, status) {
    commit('setShowMessageForm', status)
  },

  setShowMessageFormInThread({ commit }, status) {
    commit('setShowMessageFormInThread', status)
  },

  setViewMessageIndex({ commit }, index) {
    commit('setViewMessageIndex', index)
  },

  async toggleMessageReadStatus({ commit }, message) {
    try {
      const res = await this.$axios.$put(`/message/read/${message._id}`, { read: message.read })
      if (res.result === 'ok') {
        commit('toggleMessageRead', message)
      }
    } catch (err) {
      // Ignore errors produced here as most of the time it's due to the message being deleted
      // prior to it's read status being updated.
    }
  },

  async updateDraft({ dispatch }, message) {
    try {
      const res = await this.$axios.$put('/message', message)
      return res.messageId
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'messages/updateDraft', info: err },
        { root: true })
    }
  }
}

export const mutations = {
  init(state) {
    state.fetchingMessages = false
    state.listMessageId = ''
    state.messageList = []
    state.messageListCount = 0
    state.messageRecsRetrieved = 0
    state.viewMessageIndex = 0
    state.activeMessageFull = []
    state.inboxOptions = {
      activeMessageFilter: 'all',
      activeMessageFolder: 'inbox',
      activeMessageSort: 'newest',
      messageListLimit: '50',
      messageListSkip: '',
      messageSearchTerm: '',
      messagePage: 0
    }
    state.messageParticipant = {}
    state.messageAllParticipants = {}
    state.messageBuyLicenses = {}
    state.messageFormMode = ''
    state.referralMessageId = ''
    state.referredNonAuthUser = ''
    state.selectedMessages = {
      inbox: [],
      drafts: [],
      sent: [],
      archived: [],
      trash: []
    }
    state.showMessageForm = false
    state.showMessageFormInThread = false
    state.projectList = []
  },

  addSelectedMessageId(state, id) {
    const folder = state.inboxOptions.activeMessageFolder
    state.selectedMessages[folder].push(id)
  },

  clearSelectedMessages(state) {
    state.selectedMessages = {
      inbox: [],
      drafts: [],
      sent: [],
      archived: [],
      trash: []
    }
  },

  dropDownInUse(state, status) {
    state.dropDownInUse = status
  },

  removeSelectedMessageId(state, id) {
    const folder = state.inboxOptions.activeMessageFolder
    state.selectedMessages[folder].splice(state.selectedMessages[folder].indexOf(id), 1)
  },

  setFolderOptions(state, counts) {
    const folderOptions = []
    const folders = ['Inbox', 'Drafts', 'Archived', 'Sent', 'Trash']
    folders.forEach((f) => {
      const c = counts[f.toLowerCase()]
      folderOptions.push(
        {
          label: f + (c > 0 ? ` (${c})` : ''),
          value: f.toLowerCase()
        }
      )
    })
    state.folderOptions = folderOptions
  },

  setListMessageId(state, id) {
    state.listMessageId = id
  },

  setViewMessageIndex(state, index) {
    state.viewMessageIndex = index
  },

  setActiveMessageFull(state, message) {
    state.activeMessageFull = message
  },

  setActiveMessageFilter(state, filter) {
    state.inboxOptions.activeMessageFilter = filter
  },

  setActiveMessageFolder(state, folder) {
    state.inboxOptions.activeMessageFolder = folder
  },

  setActiveMessageSort(state, order) {
    state.inboxOptions.activeMessageSort = order
  },

  setAvailableRecipients(state, users) {
    state.availableRecipients = users
  },

  setMessageFormMode(state, mode) {
    state.messageFormMode = mode
  },

  setMessageList(state, messages) {
    if (state.inboxOptions.messagePage > 0) {
      const newRecsRetrieved = state.messageRecsRetrieved + messages.length
      if (newRecsRetrieved <= state.messageListCount) {
        state.messageList.push(...messages)
        state.messageRecsRetrieved = newRecsRetrieved
      }
    } else {
      state.messageList = messages
      state.messageRecsRetrieved = messages.length
    }
  },

  setMessageListCount(state, count) {
    state.messageListCount = count
  },

  setMessageListPage(state, page) {
    if (page || page === 0) {
      state.inboxOptions.messagePage = page
    } else {
      const incPage = state.inboxOptions.messagePage + 1
      const skippedRecs = incPage * state.inboxOptions.messageListLimit
      if (state.messageListCount && skippedRecs < state.messageListCount) {
        state.fetchingMessages = true
        state.inboxOptions.messagePage++
      } else {
        state.fetchingMessages = false
      }
    }
  },

  setFetchingMessages(state, fetching) {
    state.fetchingMessages = fetching
  },

  setMessageListIndex(state, index) {
    state.messageListIndex = index
  },

  setMessageListLimit(state, limit) {
    state.inboxOptions.messageListLimit = limit
  },

  setMessageListSkip(state, skip) {
    state.inboxOptions.messageListSkip = skip
  },

  setMessageBuyLicenses(state, { type, org }) {
    state.messageBuyLicenses.type = type
    state.messageBuyLicenses.org = org
  },

  setMessageParticipant(state, { email, projectid }) {
    state.messageParticipant.email = email
    state.messageParticipant.projectid = projectid
  },

  setMessageProjectOwner(state, { email, projectid }) {
    state.messageProjectOwner.projectid = projectid
    state.messageProjectOwner.email = email
  },

  setMessageAllParticipants(state, { emails, projectid, status }) {
    state.messageAllParticipants.emails = emails
    state.messageAllParticipants.projectid = projectid
    state.messageAllParticipants.active = status
  },

  setMessageSearchTerm(state, search) {
    state.inboxOptions.messageSearchTerm = search
  },

  setProjectList(state, projects) {
    state.projectList = projects
  },

  setReferralMessageId(state, id) {
    state.referralMessageId = id
  },

  setReferredNonAuthUser(state, email) {
    state.referredNonAuthUser = email
  },

  setShowMessageForm (state, status) {
    state.showMessageForm = status
  },

  setShowMessageFormInThread (state, status) {
    state.showMessageFormInThread = status
  },

  setUnreadMessageCount(state, count) {
    state.unreadMessageCount = count
  },

  toggleMessageRead(state, message) {
    state.messageList.forEach((mi) => {
      if (mi._id === message._id) {
        mi.read = message.read
      }
    })
  },

  unreadMessageCountFailsIncrement(state) {
    state.unreadMessageCountFails++
  },

  unreadMessageCountFailsZero(state) {
    state.unreadMessageCountFails = 0
  }
}
