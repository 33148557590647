import jwtDecode from 'jwt-decode'
import {
  userRoles,
  userProjectRoles,
  isSuperAdmin
} from '../server/constants'

export const state = () => ({
  user: null,
  actingAs: userRoles.newUser,
  impersonationUserId: null,
  impActingAs: '',
  token: '',
  hInterval: null,
  hMsgInterval: null,
  hResendInterval: null,
  canResendConfirmationEmail: true,
  resendIntervalCounter: 0,
  activeUserId: null,
  activeUser: null,
  users: [],
  pendingUsers: [],
  organizations: [],
  userCount: 0,
  userRecsRetrieved: 0,
  adminPendingOptions: {
    filter: 'all',
    sort: '',
    limit: 100,
    skip: 0,
    search: '',
    page: 0
  },
  adminOptions: {
    filter: 'all',
    sort: '',
    limit: 100,
    skip: 0,
    search: '',
    page: 0
  },
  networkCall: false
})

export const getters = {
  getActiveUserId: state => state.activeUserId,
  getActiveUser: state => state.activeUser,
  getAdminOptions: state => state.adminOptions,
  getUsers: state => state.users,
  getPendingUsers: state => state.pendingUsers,
  getOrgList: state => state.organizations,
  getUserCount: state => state.userCount,
  fullName: state => state.user
    ? state.user.profile.firstName + ' ' + state.user.profile.lastName
    : '',
  getUserRole: state => state.user?.role,
  getUserActingAs: state => state.actingAs,
  getImpersonationUserId: state => state.impersonationUserId,
  getImpActingAs: state => state.impActingAs,
  getFieldSort: state => state.adminOptions.sort,
  loggedInUser: state => state.token?.length > 0,
  token: state => state.token,
  user: state => state.user,
  getUserSettingLandingPage: state => state.user?.settings?.landingPage || 'dashboard',
  userId: state => state.user?._id,
  userOid: state => state.user?.organization?._id || state.user.organization,
  isAdmin: (state) => {
    const role = state.user?.role || 0
    return !!(role & userRoles.admin)
  },
  isOrgAdmin: (state) => {
    const role = state.user?.role || 0
    return !!(role & userRoles.admin) &&
           !!(role & userRoles.orgAdmin)
  },
  isListAdmin: (state) => {
    const role = state.user?.role || 0
    return !!(role & userRoles.admin) &&
           !!(role & userRoles.listAdmin)
  },
  isSuperAdmin: (state) => {
    const role = state.user?.role || 0
    return !!(role & userRoles.admin) &&
            !(role & userRoles.listAdmin) &&
            !(role & userRoles.orgAdmin)
  },
  isProjectOwner: (state) => {
    return !!(state.actingAs & userRoles.projectowner)
  },
  isParticipant: (state) => {
    return !!(state.actingAs & userRoles.participant)
  },
  isReviewer: (state) => {
    return !!(state.user?.role & userRoles.reviewer)
  },
  isActingAsReviewer: (state) => {
    return !!(state.actingAs & userRoles.reviewer)
  },
  isDelegator: (state) => {
    return !!(state.actingAs & userRoles.participant && state.actingAs & userProjectRoles.delegator)
  },
  isCollaborator: (state) => {
    return !!(state.user.role & userRoles.collaborator)
  },
  isUserLoisTempNoCommunities: (state, getters) => {
    const communitiesSelected = getters.isUserInternationalParticipant || (!getters.isUserInternationalParticipant && !getters.isUserMissingCommunities)
    return getters.isUserLoisTemp && !communitiesSelected
  },
  isUserLoisTemp: (state) => {
    return state.user?.loisTemp
  },
  isUserInternationalParticipant: (state) => {
    return state.user?.settings?.regionIntl
  },
  isUserMissingCommunities: (state) => {
    return !state.user?.loisCommunities.length
  },
  isOwnerAndParticipant: state =>
    !!(state.user?.role & userRoles.projectowner) &&
    !!(state.user?.role & userRoles.participant),
  isOwnerOrParticipant: state =>
    !!(state.user?.role & userRoles.projectowner) ||
    !!(state.user?.role & userRoles.participant),
  isReallyASuperAdmin: (state) => {
    if (state.token) {
      const { role } = jwtDecode(state.token.split(':')[0])
      return role & userRoles.admin
    }
    return false
  },
  getCanResendConfirmationEmail: state => state.canResendConfirmationEmail,
  getResendIntervalCounter: state => state.resendIntervalCounter
}

export const actions = {

  async addUser({ commit, dispatch }, adminForm) {
    try {
      const res = await this.$axios.$post('/user', adminForm)
      commit('resetOptions')
      return res.result === 'ok'
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'user/addUser', info: err },
        { root: true })
    }
  },

  addProjectRoleToActingAs({ state, commit }, role) {
    if (state.actingAs & userRoles.participant) {
      if (role & userProjectRoles.delegate || role & userProjectRoles.delegator) {
        commit('setActingAs', userRoles.participant + role)
        localStorage.setItem('aas', state.user.role + role)
        const [tkn] = state.token.split(':')
        commit('setToken', tkn)
      }
    }
  },

  async orgAdmissionResponse({ dispatch }, { userId, admit = false }) {
    try {
      const res = await this.$axios.$post('/user/organization/admission', { _id: userId, admit })
      if (res.result === 'ok') {
        await dispatch('fetchPendingOrganizationUserList')
        await dispatch('fetchOrganizationUserList')
      }
      return true
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'user/orgAdmissionResponse', info: err },
        { root: true })
    }
  },

  async orgAdmissionRequest({ dispatch }, { email }) {
    try {
      const res = await this.$axios.$post('/user/organization/admission/request', { email })
      if (res.result === 'ok') {
        return 'ok'
      }
      return res.message
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'user/orgAdmissionRequest', info: err },
        { root: true })
    }
  },

  // This routine is very destructive, so... before we allow anyone to
  // delete a user, please notify them that this will remove all projects
  // they are the owner of, all participant records, answers, everything.
  // There is NO UNDO button for this shit...
  async deleteUser({ dispatch, commit }, { userId }) {
    try {
      const res = await this.$axios.$delete(`/user/${userId}`)
      commit('resetOptions')
      return res.result === 'ok'
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'user/deleteUser', info: err },
        { root: true }
      )
    }
  },

  async fetchUserCompanyAndName({ dispatch }, email) {
    try {
      const user = await this.$axios.$get(`/user/register/profile/${email}`)
      return user
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'user/fetchUserCompanyAndName', info: err },
        { root: true }
      )
    }
  },

  async fetchUserOrganization({ state, commit, dispatch }) {
    try {
      if (state.user?.organization?.name) {
        return state.user.organization
      }
      const org = await this.$axios.get('/api/v1/user/organization')
      commit('setUserOrganization', org)
      return state.user.organization
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'user/fetchUserOrganization', info: err },
        { root: true }
      )
    }
  },

  // actingAs can be a participant or project owner, but not both or anything else
  setUserActingAs({ state, commit }, role) {
    if (state.user.role & userRoles.admin && !(role & userRoles.admin)) {
      role += userRoles.admin
    }
    if (role & userRoles.participant &&
       (state.user.role & userRoles.participant || state.user.role & userRoles.admin)) {
      commit('setActingAs', role)
    } else if (role & userRoles.projectowner &&
              (state.user.role & userRoles.projectowner || state.user.role & userRoles.admin)) {
      commit('setActingAs', role)
    } else if (role & userRoles.collaborator) {
      commit('setActingAs', role)
    } else if (role & userRoles.reviewer) {
      commit('setActingAs', role + userRoles.projectowner)
    }
    localStorage.setItem('aas', state.actingAs)
    const [tkn] = state.token.split(':')
    commit('setToken', tkn)
  },

  async setActiveUserId({ state, commit, dispatch }, userId) {
    if (!userId) {
      commit('setActiveUserId', null)
      commit('setActiveUser', null)
      return
    }
    try {
      if ((state.user.role & userRoles.admin)) {
        commit('setActiveUserId', userId)
        const user = await dispatch('fetchUserById', userId)
        return user
      }
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'user/setActiveUserId', info: err },
        { root: true })
    }
  },

  // returns false if email is NOT available, true if email is available
  async checkEmail({ dispatch }, email) {
    try {
      const res = await this.$axios.$get(`/user/check/${email}`)
      return res.result
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'user/checkEmail', info: err },
        { root: true }
      )
    }
  },

  async fetchOrgList({ commit, dispatch }, { org }) {
    try {
      const qs = `?org=${(org || '').replace(/\\/g, '')}`
      const res = await this.$axios.$get(`/user/organizations${qs}`)
      commit('setOrgList', res)
      return true
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'user/fetchOrgList', info: err },
        { root: true }
      )
    }
  },

  async fetchUserList({ commit, state, dispatch }) {
    const {
      filter,
      sort,
      limit,
      page,
      search
    } = state.adminOptions
    const skip = page * limit
    const params = [
      filter ? `filter=${filter}` : '',
      sort ? `sortby=${sort}` : '',
      limit ? `limit=${limit}` : '',
      skip ? `skip=${skip}` : '',
      search ? `search=${search}` : ''
    ]
    try {
      const usersRes = await this.$axios.$get('/users?' + params.filter(p => p).join('&'))
      commit('setUserCount', usersRes.count)
      commit('setUsers', usersRes.users)
      return true
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'user/fetchUserList', info: err },
        { root: true })
    }
  },

  async fetchOrganizationUserList({ commit, state, dispatch }) {
    const {
      filter,
      sort,
      limit,
      page,
      search
    } = state.adminOptions
    const skip = page * limit
    const params = [
      filter ? `filter=${filter}` : '',
      sort ? `sortby=${sort}` : '',
      limit ? `limit=${limit}` : '',
      skip ? `skip=${skip}` : '',
      search ? `search=${search}` : ''
    ]
    try {
      const usersRes = await this.$axios.$get('/users/by/organization?' + params.filter(p => p).join('&'))
      commit('setUserCount', usersRes.count)
      commit('setUsers', usersRes.users)
      return true
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'user/fetchUserList', info: err },
        { root: true })
    }
  },

  async fetchPendingOrganizationUserList({ commit, dispatch }) {
    try {
      const usersRes = await this.$axios.$get('/users/by/organization/pending')
      commit('setPendingUsers', usersRes.users)
      return true
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'user/fetchUserList', info: err },
        { root: true })
    }
  },

  async fetchUserById({ commit, dispatch }, userId) {
    try {
      const user = await this.$axios.$get(`/user/id/${userId}`)
      if (user.organization && user.organization.name) {
        user.orgId = user.organization._id
        user.organization = user.organization.name
      }
      commit('setActiveUser', user)
      return user
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'user/fetchUserById', info: err },
        { root: true })
    }
  },

  async moveProjectsToUser({ dispatch }, { toUserId, fromUserId }) {
    try {
      const res = await this.$axios.$post('/user/move/projects', { toUserId, fromUserId })
      return res.result === 'ok'
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'user/moveProjectsToUser', info: err },
        { root: true })
    }
  },

  async setImpersonationUserId({ state, dispatch, commit }, impersonationUserId) {
    try {
      if (!isSuperAdmin(state.user.role)) {
        throw new Error('access forbidden')
      }
      localStorage.setItem('aas', userRoles.newUser)
      localStorage.setItem('iuid', impersonationUserId)
      const res = await dispatch('refreshToken', true)
      dispatch('global/loginInit', null, { root: true })
      return res
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'user/setImpersonationUserId', info: err },
        { root: true })
    }
  },

  async login({ commit, dispatch }, loginForm) {
    // The login post request should throw a 401 error if it fails, which
    // should be caught by caller.
    const { token, tokenExpiry, redirectUrl } = await this.$axios.$post('/login', loginForm)
    if (redirectUrl) {
      window.location.href = redirectUrl
      return
    }
    // Set $axios's token for future requests
    this.$axios.setToken(token, 'Bearer')
    // Retrieve info about myself
    const user = await this.$axios.$get('/user/me')
    commit('setUser', user)
    // When we first login, set highest level of access
    let actingAs = parseInt(user.role) & parseInt(userRoles.admin)
    if (user.role & userRoles.projectowner) {
      actingAs += userRoles.projectowner
    } else if (user.role & userRoles.participant) {
      actingAs += userRoles.participant
    }
    commit('setActingAs', actingAs)
    localStorage.setItem('aas', actingAs)
    // Set the JWT and expiry into Vuex store.
    commit('setToken', token)
    let refreshInterval = (tokenExpiry - Date.now()) * 0.75
    if (refreshInterval > 1000000 && refreshInterval < 0) {
      refreshInterval = 1000000
    }
    const hInterval = setInterval(async function() {
      await dispatch('refreshToken')
    }, refreshInterval)
    const hMsgInterval = setInterval(async function() {
      await dispatch('messages/fetchUnreadMessageCount', null, { root: true })
    }, 60000)
    commit('setIntervalHandle', hInterval)
    commit('setMsgIntervalHandle', hMsgInterval)
    await dispatch('messages/fetchUnreadMessageCount', null, { root: true })
  },

  async logoutImpersonatedUser({ commit, dispatch }) {
    try {
      localStorage.removeItem('iuid')
      localStorage.removeItem('aas')
      commit('init')
      // forcing refreshToken to reset the user details and ActingAs state
      // value by sending true as parameter
      const res = await dispatch('refreshToken', true)
      // only call global/logoutInit when we are "really" logging out
      // not when we stop impersonating
      dispatch('global/loginInit', null, { root: true })
      commit('setUsers', [])
      commit('setUserCount', 0)
      commit('resetOptions')
      return res
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'user/logoutimpersonatedUser', info: err },
        { root: true })
    }
  },

  async logout({ commit, state, dispatch }, skipRedirect = false) {
    try {
      if (state.networkCall) {
        return
      }
      commit('setNetworkCall', true)
      await this.$axios.$post('/logout', null, { timeout: 6000 })
    } catch (e) {
      // if an error occurs it's because the network is down or off
      // so ignore this error silently, and just get out
    } finally {
      commit('setNetworkCall', false)
      this.$axios.setToken(false)
      localStorage.removeItem('iuid')
      localStorage.removeItem('aas')
      commit('resetOptions')
      clearInterval(state.hInterval)
      clearInterval(state.hMsgInterval)
      dispatch('global/logoutInit', null, { root: true })
      if (!skipRedirect) {
        this.$router.push('/')
      }
    }
  },

  async refreshToken({ dispatch, commit, state }, force) {
    try {
      let querystring = ''
      if (localStorage.getItem('aas') && !state.actingAs) {
        commit('setActingAs', parseInt(localStorage.getItem('aas')))
      }
      if (state.actingAs) {
        querystring = `?acting=${state.actingAs}`
      }
      if (localStorage.getItem('iuid') && !state.impersonationUserId) {
        commit('setImpersonationUserId', localStorage.getItem('iuid'))
      }
      if (state.impersonationUserId) {
        querystring = querystring
          ? `${querystring}&impersonationUserId=${state.impersonationUserId}`
          : `?impersonationUserId=${state.impersonationUserId}`
      }
      const { code, token, tokenExpiry } = await this.$axios
        .$get(`/refreshtoken${querystring}`)
      if (code && code !== 200) {
        if (force === 0) {
          return
        }
        return dispatch('logout')
      }
      // immediately set the token we use in axios
      this.$axios.setToken(token, 'Bearer')
      if (!state.user || force) {
        const user = await this.$axios.$get('/user/me')
        commit('setUser', user)
        const [tkn, aas, iuid] = token.split(':')
        if (state.impersonationUserId && iuid === state.impersonationUserId) {
          commit('setActingAs', parseInt(aas))
        } else if (tkn) {
          localStorage.removeItem('iuid')
          let actingAs = parseInt(aas)
          if (user.role & userRoles.admin &&
              !(actingAs & userRoles.admin)) {
            actingAs += userRoles.admin
          }
          if (!(actingAs & userRoles.projectowner) &&
              !(actingAs & userRoles.participant)) {
            actingAs += user.role & userRoles.projectowner
              ? userRoles.projectowner
              : userRoles.participant
          }
          if (state.user.role & userRoles.collaborator) {
            actingAs = state.user.role
          }
          commit('setActingAs', actingAs)
        }
        localStorage.setItem('aas', state.actingAs)
      }
      commit('setToken', token)

      // Restart timer for refreshing JWT
      clearInterval(state.hInterval)
      let refreshInterval = (tokenExpiry - Date.now()) * 0.75
      if (refreshInterval > 1000000 || refreshInterval < 0) {
        refreshInterval = 1000000 // 16.7 minutes
      }
      const hInterval = setInterval(async function() {
        await dispatch('refreshToken')
      }, refreshInterval)
      commit('setIntervalHandle', hInterval)

      // Restart message polling if stopped
      if (!state.hMsgInterval) {
        const hMsgInterval = setInterval(async function() {
          try {
            await dispatch('messages/fetchUnreadMessageCount', null, { root: true })
          } catch (err) {
            // ignore silently
          }
        }, 60000)
        commit('setMsgIntervalHandle', hMsgInterval)
      }

      await dispatch('messages/fetchUnreadMessageCount', null, { root: true })
      return true
    } catch (e) {
      try {
        console.error('error in refreshToken, calling logout')
        await dispatch('logout')
      } catch (err) {
        // ignore
      }
    }
  },

  async registerUser({ dispatch, commit }, registerForm) {
    try {
      const res = await this.$axios.$post('/user/register', registerForm)
      if (res.login) {
        await dispatch('refreshToken')
      }
      return res
    } catch (e) {
      throw new Error(e.response.data)
    }
  },

  async resendConfirmationEmail({ state, commit, dispatch }, { email }) {
    if (!state.canResendConfirmationEmail) {
      return false
    }
    const res = await this.$axios.$post('/user/resend', { email })
    commit('setCanResendConfirmationEmail', false)
    commit('setResendIntervalCounter', this.$config.emailConfirmationMinSendInterval)
    const hInterval = setInterval(() => {
      if (state.resendIntervalCounter > 0) {
        return commit('setResendIntervalCounter', 1)
      }
      commit('clearResendIntervalHandle')
      commit('setCanResendConfirmationEmail', true)
    }, 1000)
    commit('setResendIntervalHandle', hInterval)
    return res.result === 'ok'
  },

  async resetPassword({ commit }, resetForm) {
    try {
      const res = await this.$axios.post('/user/forget', { resetForm })
      return res
    } catch (e) {
      throw new Error(e.response.data)
    }
  },

  setUserListFilter({ commit }, filter) {
    commit('setUserListFilter', filter)
  },

  setUserListSort({ commit }, order) {
    commit('setUserListSort', order)
  },

  setUserListLimit({ commit }, limit) {
    commit('setUserListLimit', limit)
  },

  setUserListSkip({ commit }, skip) {
    commit('setUserListSkip', skip)
  },

  resetUserListPage({ commit }) {
    commit('setUserListPage', 0)
  },

  incrementUserListPage({ commit }) {
    commit('setUserListPage')
  },

  resetOptions({ commit }) {
    commit('resetOptions')
  },

  setUserListSearchTerm({ commit }, search) {
    commit('setUserListSearchTerm', search)
  },

  async updateUserByAdmin({ commit, dispatch }, user) {
    try {
      const res = await this.$axios.$put('/user', { user })
      commit('setActiveUserId', res._id)
      commit('setActiveUser', res)
      commit('resetOptions')
      return res
    } catch (err) {
      dispatch(
        'global/setNetworkError',
        { method: 'user/updateUserByAdmin', info: err },
        { root: true })
    }
  },

  async updateUserProfile({ commit, dispatch }, { id, user }) {
    try {
      const profile = await this.$axios.$put(`/user/profile/${id}`, user)
      commit('setUserFromProfile', profile)
      return profile
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'user/updateUserProfile', info: err },
        { root: true })
    }
  }
}

export const mutations = {
  init(state) {
    state.user = null
    state.users = []
    state.pendingUsers = []
    state.actingAs = userRoles.newUser
    state.impersonationUserId = null
    state.impActingAs = null
    state.token = ''
    if (state.hInterval) {
      clearInterval(state.hInterval)
    }
    state.hInterval = null
    if (state.hMsgInterval) {
      clearInterval(state.hMsgInterval)
    }
    state.hMsgInterval = null
    if (state.hResendInterval) {
      clearInterval(state.hResendInterval)
    }
    state.hResendInterval = null
    state.activeUserId = null
    state.adminOptions = {
      filter: 'all',
      sort: '',
      limit: 100,
      skip: 0,
      search: '',
      page: 0
    }
    state.networkCall = false
  },
  resetOptions(state) {
    state.adminOptions = {
      filter: 'all',
      sort: '',
      limit: 100,
      skip: 0,
      search: '',
      page: 0
    }
  },
  setActiveUserId(state, userId) {
    state.activeUserId = userId
  },
  setActiveUser(state, user) {
    state.activeUser = user
  },
  setActingAs(state, role) {
    state.actingAs = role
  },
  setImpersonationUserId(state, impersonationUserId) {
    state.impersonationUserId = impersonationUserId
  },
  setImpersonationUserRole(state, impActingAs) {
    state.impActingAs = impActingAs
  },
  setIntervalHandle(state, hInterval) {
    state.hInterval = hInterval
  },
  setMsgIntervalHandle(state, hMsgInterval) {
    state.hMsgInterval = hMsgInterval
  },
  clearMsgIntervalHandle(state) {
    if (state.hMsgInterval) {
      clearInterval(state.hMsgInterval)
      state.hMsgInterval = null
    }
  },
  setResendIntervalCounter(state, increment) {
    if (increment > 1) {
      state.resendIntervalCounter = increment
    } else {
      state.resendIntervalCounter -= increment
    }
  },
  clearResendIntervalHandle(state) {
    clearInterval(state.hResendInterval)
    state.hResendInterval = null
  },
  setResendIntervalHandle(state, hResendInterval) {
    state.hResendInterval = hResendInterval
  },
  setCanResendConfirmationEmail(state, canSend) {
    state.canResendConfirmationEmail = canSend
  },
  setOrgList(state, list) {
    state.organizations = list
  },
  setUser(state, user) {
    state.user = user
    state.actingAs = user.role
  },
  setUserFromProfile(state, user) {
    state.user = user
  },
  setUserCommunities(state, loisCommunities) {
    state.user.loisCommunities = loisCommunities
  },
  setPendingUsers(state, users) {
    state.pendingUsers = users
  },
  setUsers(state, users) {
    if (state.adminOptions.page > 0) {
      const newRecsRetrieved = state.userRecsRetrieved + users.length
      if (newRecsRetrieved <= state.userCount) {
        state.users.push(...users)
        state.userRecsRetrieved = newRecsRetrieved
      }
    } else {
      state.users = users
      state.userRecsRetrieved = users.length
    }
  },
  setUserCount(state, count) {
    state.userCount = count
  },
  setUserListFilter(state, filter) {
    state.adminOptions.filter = filter
  },
  setUserListSort(state, sort) {
    state.adminOptions.page = 0
    state.adminOptions.sort = sort
  },
  setUserListLimit(state, limit) {
    state.adminOptions.limit = limit
  },
  setUserListSkip(state, skip) {
    state.adminOptions.skip = skip
  },
  setUserListPage(state, page) {
    if (page || page === 0) {
      state.adminOptions.page = page
    } else {
      const incPage = state.adminOptions.page + 1
      const skippedRecs = incPage * state.adminOptions.limit
      if (state.userCount && skippedRecs < state.userCount) {
        state.adminOptions.page++
      }
    }
  },
  setUserListSearchTerm(state, search) {
    state.adminOptions.page = 0
    state.adminOptions.search = search
  },
  setUserOrganization(state, org) {
    state.user.organization = org
  },
  setToken(state, token) {
    const imp = state.impersonationUserId ? state.impersonationUserId : ''
    const newToken = `${token}:${state.actingAs}:${imp}`
    this.$axios.setToken(newToken, 'Bearer')
    state.token = newToken
  },
  setNetworkCall(state, networkCall) {
    state.networkCall = networkCall
  }
}
